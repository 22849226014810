import React from "react";

import { TermOfUseRU } from "./TermOfUseRU";
import { TermOfUseEng } from "./TermOfUseENG";

import i18n from "../../i18n/i18n";

export const TermOfUse = () => {
  const [curLanguage, changeCurLanguage] = React.useState(i18n.language);
  React.useEffect(() => {
    changeCurLanguage(i18n.language);
  }, []);
  if (curLanguage === "ru") {
    return <TermOfUseRU />;
  } else {
    return <TermOfUseEng />;
  }
};
