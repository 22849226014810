import React from "react";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import style from "./footer.module.scss";
import logo from "./logo.svg";

export const Footer = ({ changeModal }) => {
  const { t } = useTranslation();

  return (
    <footer className={style.footer}>
      <div className="container">
        <div className={style["row"]}>
          <div className={style["logo-col"]}>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>

          <div className={style["main-col"]}>
            <div className={style["nav-info"]}>
              <div className={style["tt"]}>{t("footer.title_1")}</div>
              <ul className={style["nav"]}>
                <li>
                  <Link to="/">{t("footer.nav_1_item_1")}</Link>
                </li>
                {/* <li>
                  <a>{t("footer.nav_1_item_2")}</a>
                </li> */}
              </ul>
            </div>

            <div className={style["nav-info"]}>
              <div className={style["tt"]}>{t("footer.title_2")}</div>
              <ul className={style["nav"]}>
                <li
                  onClick={() => changeModal({ isOpen: true, name: "PRIVACY" })}
                >
                  <a href="#privacy">{t("footer.nav_2_item_1")}</a>
                </li>
                <li
                  onClick={() =>
                    changeModal({ isOpen: true, name: "TERMOFUSE" })
                  }
                >
                  <a href="#termofuse">{t("footer.nav_2_item_2")}</a>
                </li>
                <li
                  onClick={() =>
                    changeModal({ isOpen: true, name: "EDUCATIONAL_LICENSE" })
                  }
                >
                  <a href="#educational-license">
                    {t("footer.educational_license")}
                  </a>
                </li>
                <li
                  onClick={() =>
                    changeModal({ isOpen: true, name: "LICENSE_REGISTRY" })
                  }
                >
                  <a href="#license-registry">
                    {t("footer.license_registry")}
                  </a>
                </li>
              </ul>
            </div>

            <div className={style["nav-info"]}>
              <div className={style["tt"]}>{t("footer.title_3")}</div>
              <ul className={style["nav"]}>
                <li>
                  <span className={style["tt"]}></span>
                  +7 (499) 288-07-29
                </li>
                <li>
                  <span className={style["tt"]}></span>
                  {t("footer.labarhimed_working_hours")}
                </li>
                <li>
                  <span className={style["icon-envelope"]}></span>
                  <a href="malito:arhimed@arhimedlab.com">
                    arhimed@arhimedlab.com
                  </a>
                </li>
              </ul>
            </div>

            <div className={style.copy}>&copy; {t("footer.copy")}</div>
          </div>

          <div className={style["lang-col"]}>
            <ChangeLanguage />
          </div>
        </div>
      </div>
    </footer>
  );
};
