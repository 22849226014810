import React from "react";
import { ReactSVG } from "react-svg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import ru from "react-phone-number-input/locale/ru";
import en from "react-phone-number-input/locale/en";

import { useStores } from "../../models";

import { Input, Button } from "..";

import style from "./form.module.scss";
import numberApproved from "./number-approved.svg";
import { Checkbox } from "../Checkbox";
import { observer } from "mobx-react";

const STATUSES = {
  PHONE_FILLING: 1,
  CODE_FILLING: 2,
  CODE_APPROVED: 3,
};

export const ApproveNumberForm = observer(({ ...props }) => {
  const { phoneNumberApproved } = props;
  const [timer, changeTimer] = React.useState(60000);
  const [phoneNumber, changePhoneNumber] = React.useState("");
  const [email, changeEmail] = React.useState("");
  const [code, changeCode] = React.useState(null);
  const [status, changeStatus] = React.useState(STATUSES.PHONE_FILLING);
  const { t } = useTranslation();
  const [iamAgree, changeIamAgree] = React.useState(true);
  const [emailExist, changeEmailExist] = React.useState(false);
  const { userStore } = useStores();

  const sendCode = () => {
    userStore.sendCode(phoneNumber.replace(/[^0-9]/g, ""), email);
  };
  const checkCode = (codeValue) => {
    userStore.checkCode(
      phoneNumber.replace(/[^0-9]/g, ""),
      `${codeValue}`,
      email,
    );
  };
  React.useEffect(() => {
    if (userStore.user.username) {
      changePhoneNumber(userStore.user.username);
      onChangeEmail(userStore.user.email);
      changeStatus(STATUSES.CODE_APPROVED);
      changeIamAgree(true);
    } else {
      changePhoneNumber("");
      onChangeEmail("");
      changeStatus(STATUSES.PHONE_FILLING);
    }
  }, [userStore.user.email, userStore.user.username]);

  const openPrivacyPolicyPdf = () => {
    window.open(
      "./agreement.pdf",
      "_blank",
    );
  }

  const openAgreementPdf = () => {
    window.open(
      "./policy.pdf",
      "_blank",
    );
  }

  React.useEffect(() => {
    if (status === STATUSES.CODE_FILLING) {
      const timerId = setInterval(
        () =>
          changeTimer((val) => {
            if (val <= 0) {
              return 0;
            }
            return val - 1000;
          }),
        1000,
      );
      return () => clearInterval(timerId);
    }
  }, [status, timer]);

  React.useEffect(() => {
    changeEmail("");
  }, [emailExist]);
  const onChangeCode = async (value) => {
    changeCode(value);
    const codeValue = Number.parseInt(value);
    if (`${codeValue}`.length === 4) {
      checkCode(codeValue);
    }
  };
  const onChangeEmail = async (value) => {
    changeEmail(value.replace(/\s/g, ""));
  };

  const resetNumber = () => {
    changeCode("");
    changeStatus(STATUSES.PHONE_FILLING);
    // userStore.clearUser();
  };

  const CodeCmp = () => {
    const txt = t("sendsms");
    const isDisabled =
      phoneNumber !== undefined
        ? phoneNumber.replace(/[^\d]/g, "").length < 11 ||
          !iamAgree ||
          !emailExist
          ? !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
          : false
        : true;
    switch (status) {
      case STATUSES.PHONE_FILLING:
        return (
          <Button
            onClick={() => {
              changeStatus(STATUSES.CODE_FILLING);
              sendCode();
            }}
            disabled={isDisabled}
            uitype={isDisabled ? "disabled" : ""}
          >
            <span>{txt}</span>
          </Button>
        );
      case STATUSES.CODE_FILLING:
        return (
          <div className={style.codeFilingWrapper}>
            <h4 className={style.enterCodePhone}>{t("enterCode")}</h4>
            <Input
              name="smsConfirm"
              mask="9999"
              onChange={onChangeCode}
              value={code}
            />
            {timer === 0 ? (
              <div>
                <Button
                  onClick={() => {
                    changeTimer(60000);
                    sendCode();
                  }}
                  className={style.sendAgainBtn}
                >
                  {t("send_again_btn")}
                </Button>
              </div>
            ) : (
              <>
                <div
                  className={cn(style.sendAgainTxt, {
                    [style["sendAgainTxt--header"]]: props.header,
                  })}
                >
                  {t("send_again")} {timer / 1000} {t("sec")}
                </div>
              </>
            )}
            <div className={style.changeNumber}>{renderChangeNumber()}</div>
          </div>
        );
      case STATUSES.CODE_APPROVED:
        return (
          <div>
            <div className={style.numbeApprovedTxt}>
              <ReactSVG src={numberApproved} />
              <p>{t("phoneNumerAccepted")}</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderChangeNumber = () => (
    <Button onClick={resetNumber}>
      {/* <div className={style.changeNumber} onClick={resetNumber}> */}
      {t("changePhoneNumber")}
      {/* </div> */}
    </Button>
  );
  return (
    <div className={style.wrap}>
      <div className={cn(style.top, { [style["top--modal"]]: props.header })}>
        <PhoneInput
          className={style.phoneNumb}
          name="phoneNumber"
          onChange={changePhoneNumber}
          flags={flags}
          value={phoneNumber}
          labels={i18next.language === "ru" ? ru : en}
          placeholder={t("phoneNumber")}
          // labelClassName={cn(style.phoneNumberInput, {
          //   [style["phoneNumberInput--code"]]: status === STATUSES.CODE_FILLING,
          // })}
          disabled={phoneNumberApproved || status !== STATUSES.PHONE_FILLING}
        />
      </div>
      {status !== STATUSES.CODE_APPROVED && (
        <div className={style.emailWrap}>
          <div className={style.noEmailText}>{t("no_email")}</div>
          {!emailExist ? (
            <Input
              name="email"
              onChange={onChangeEmail}
              value={email}
              labelClassName={style.email}
            />
          ) : (
            <div className={style.noEmailText}></div>
          )}
        </div>
      )}
      {React.useMemo(CodeCmp, [
        status,
        phoneNumber,
        code,
        t,
        iamAgree,
        timer,
        email,
        emailExist,
      ])}
      <div className={style.approveTxt}>
        {status !== STATUSES.CODE_APPROVED && (
          <Checkbox
            checked={emailExist}
            onChange={(e) => changeEmailExist(e.target.checked)}
          >
            <div className={style.emailExist}>{t("dont_have_email")}</div>
          </Checkbox>
        )}

        <Checkbox
          checked={iamAgree}
          onChange={(e) =>
            status === STATUSES.PHONE_FILLING &&
            changeIamAgree(e.target.checked)
          }
        >
          <div className={style.emailExist}>
            {t("i_give")}{" "}
            <div
              className={style.link}
              onClick={openPrivacyPolicyPdf}
            >
              {t("agreement")} {""}
            </div>
          </div>
        </Checkbox>
        {/* <div style={{ margin: 4 }}/> */}
        <Checkbox
          checked={iamAgree}
          onChange={(e) =>
            status === STATUSES.PHONE_FILLING &&
            changeIamAgree(e.target.checked)
          }
        >
          <div className={style.emailExist}>
            {t("i_agree_with")}{" "}
            <div
              className={style.link}
              onClick={openAgreementPdf}
            >
              {t("policy_personal")} {""}
            </div>
          </div>
        </Checkbox>
      </div>
    </div>
  );
});
