import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Home,
  Orders,
  Order,
  ConfirmOrder,
  TermOfUse,
  PrivacyPolicy,
  NoMatchPage,
  Success,
  Failure,
} from "../screens";

import { Route } from ".";

export const Routes = () => {
  const { t } = useTranslation();
  return (
    <Router>
      <Switch>
        <Route
          component={Home}
          path="/"
          exact={true}
          title={t("how_it_works")}
        />
        <Route
          component={Order}
          path="/order"
          exact={true}
          title={t("order")}
        />
        <Route
          component={Order}
          path="/place/:placeId/product/:productId"
          exact={true}
          title={t("order")}
        />
        <Route
          component={Order}
          path="/order/port/:id/:offline?"
          exact={true}
          title={t("order")}
        />
        <Route
          component={ConfirmOrder}
          isPrivate={true}
          path="/order/:id/:cloud?"
          exact={true}
          title={t("confirm_order")}
        />
        <Route path="/orders/success" component={Success} />
        <Route path="/orders/fail" component={Failure} />
        <Route
          path="/myorders"
          title={t("myOrders")}
          component={Orders}
          isPrivate={true}
        />
        <Route
          path="/termofuse"
          component={TermOfUse}
          title={t("term_of_use_title")}
        />
        <Route
          path="/privacypolicy"
          component={PrivacyPolicy}
          title={t("privacy_title")}
        />
        <Route component={NoMatchPage} />
      </Switch>
    </Router>
  );
};
