import React from "react";
import cn from "classnames";

import style from "./button.module.scss";

export const Button = ({ children, onClick, className, ...props }) => {
  return (
    <button
      className={cn(style.btn, {
        [className]: className,
        [style[`btn--${props.uitype}`]]: props.uitype,
      })}
      onClick={onClick}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};
