import React from "react";

import style from "./politics.module.scss";

export const PrivacyPolicyRU = () => (
  <div className={style.wrap}>
    <section className={style.section}>
      <h1>Политика конфиденциальности сервиса «Клиника Архимед»</h1>
      Перед началом использования сервиса «Клиника Архимед» (далее - Сервис),
      пожалуйста, ознакомьтесь с условиями настоящей политики конфиденциальности
      (далее - Политика конфиденциальности) и
      <a href={`${window.location.origin}/termofuse`}>
        Пользовательского соглашения использования Сервиса
      </a>
      . Факт использования Сервиса любым способом (как намеренным, так и
      непреднамеренным) признается акцептом, то есть полным и безоговорочным
      согласием любого пользователя сервиса (далее - Пользователь), использовать
      Сервис на изложенных ниже условиях. Использование Сервиса допустимо
      исключительно на условиях
      <a href={`${window.location.origin}/termofuse`}>
        Пользовательского соглашения
      </a>
      и Политики конфиденциальности. Если Пользователь не принимает условия
      указанных документов в полном объеме, он не вправе использовать Сервис в
      каких-либо целях.
      <br />
      <br />
      <h4 className="t-redactor__h4">Термины и определения</h4>В настоящей
      Политике конфиденциальности используются следующие термины:
      <br />
      <ol>
        <li>
          «Персональные данные» - любая информация, относящаяся к прямо или
          косвенно определенному или определяемому Пользователю Сервиса
          (физическому лицу, субъекту персональных данных);
        </li>
        <li>
          «Обработка персональных данных» - любое действие (операция) или
          совокупность действий (операций), совершаемых с использованием средств
          автоматизации или без использования таких средств с персональными
          данными, включая сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение персональных данных;
        </li>
        <li>
          «Конфиденциальность персональных данных» - обязательное для соблюдения
          Оператором или иным получившим доступ к персональным данным лицом
          требование не допускать их распространения без согласия субъекта
          персональных данных или наличия иного законного основания.
        </li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">Общие положения</h4>
      <ol>
        <li>
          Настоящая Политика конфиденциальности представляет собой публичную
          оферту ООО "КЛИНИКА АРХИМЕД", ИНН 5003055720, адрес: 119261, г.
          Москва, ул. Вавилова, д.68, корпус 2, email: info@arhimedlab.ru,
          телефон: +7 (499) 288 07 26 (далее - Оператор) и определяет порядок
          обработки и защиты информации Пользователей Сервиса.&nbsp;
        </li>
        <li>
          Целью настоящей Политики конфиденциальности является обеспечение
          надлежащей защиты информации о Пользователях, в том числе их
          персональных данных, от несанкционированного доступа и разглашения.
        </li>
        <li>
          Отношения, связанные со сбором, хранением, распространением и защитой
          информации о Пользователях, регулируются настоящей Политикой
          конфиденциальности, иными официальными документами Оператора и
          действующим законодательством Российской Федерации.
        </li>
        <li>
          Политика конфиденциальности вступает в силу с момента авторизации
          Пользователя.&nbsp;
        </li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">Условия пользования&nbsp; Сервисом</h4>
      Оператор, действуя разумно и добросовестно, считает, что при использовании
      Сервиса Пользователь:
      <br />
      <ol>
        <li>
          обладает всеми необходимыми правами, позволяющими ему осуществлять
          регистрацию и использовать Сервис;
        </li>
        <li>
          указывает достоверную информацию о себе в объемах, необходимых для
          пользования Сервисом;
        </li>
        <li>
          ознакомлен с настоящей Политикой конфиденциальности, выражает свое
          согласие с ее положениями и принимает на себя указанные в ней права и
          обязанности.
        </li>
      </ol>
      Оператор не проверяет достоверность получаемой (собираемой) информации о
      Пользователях, за исключением случаев, когда такая проверка необходима в
      целях исполнения Оператором обязательств перед Пользователем.
      <br />
      <br />
      <h4 className="t-redactor__h4">Состав информации о Пользователях</h4>
      Оператор может собирать следующие категории персональных данных о
      Пользователе во время использования Сервисов:
      <br />
      <ol>
        <li>
          информация, предоставленная Пользователем при регистрации
          (авторизации), такая как: номер телефона, фамилия, имя, отчество (если
          имеется), дата рождения. Пользователю для целей безопасного
          использования сервиса, а также надлежащего уведомления, может быть
          направлен в SMS-сообщениях код-пароль для авторизации, ссылка на
          запись на прием, а также ссылка на результат анализа;
        </li>
        <li>серия и номер паспорта для записи на сдачу анализов;</li>
        <li>дата и время осуществления доступа к Сервису;</li>
        <li>
          дополнительно предоставляемые Пользователями по запросу Оператора в
          целях исполнения Оператором обязательств перед Пользователями.
          Оператор вправе, в частности, запросить у Пользователя копию
          документа, удостоверяющего личность, либо иного документа, содержащего
          имя, фамилию, фотографию Пользователя, а также иную дополнительную
          информацию, которая, по усмотрению Оператора, будет являться
          необходимой и достаточной для идентификации такого Пользователя и
          позволит исключить злоупотребления и нарушения прав третьих лиц.&nbsp;
        </li>
        <li>
          данные о состоянии здоровья (о результатах медицинских обследований,
          анализов и т.п.);
        </li>
        <li>
          иная информация, необходимая для обработки в соответствии с условиями,
          регулирующими использование Сервиса.
        </li>
      </ol>
      Оператор не собирает данные с целью составления «портрета» Пользователя в
      той степени, при которой это может существенно повлиять на права
      Пользователя и свободы в соответствии с применимым законодательством.
      <br />
      <br />
      <h4 className="t-redactor__h4">Цели обработки персональных данных</h4>
      Оператор всегда обрабатывает персональные данные в определенных целях и
      только те персональные данные, которые имеют отношение к достижению таких
      целей. В частности, Оператор обрабатывает персональные данные
      Пользователей для следующих целей:
      <br />
      <ol>
        <li>
          выполнение обязательств Оператора перед Пользователями в отношении
          использования Сервиса;
        </li>
        <li>предоставления доступа к Сервису;</li>
        <li>
          осуществления связи с Пользователем для направления уведомлений,
          запросов и информации, относящейся к работе Сервиса, выполнения
          соглашений и обработки запросов Пользователя;
        </li>
        <li>повышения удобства использования Сервиса;</li>
        <li>защиты прав Пользователя и Оператора.</li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">Условия обработки персональных данных</h4>
      Обработка персональных данных осуществляется на основе принципов:
      <br />
      <ol>
        <li>Законности целей и способов обработки персональных данных;</li>
        <li>Добросовестности;</li>
        <li>
          Соответствия целей обработки персональных данных целям, заранее
          определенным и заявленным при сборе персональных данных, а также
          полномочиям Оператора;
        </li>
        <li>
          Соответствия объема и характера обрабатываемых персональных данных,
          способов обработки персональных данных целям обработки персональных
          данных;
        </li>
        <li>
          Недопустимости объединения созданных для несовместимых между собой
          целей баз данных, содержащих персональные данные.
        </li>
      </ol>
      Оператор осуществляет обработку персональных данных Пользователя в целях
      исполнения договора между Оператором и Пользователем на оказание платных
      медицинских услуг. В силу статьи 6 Федерального закона от 27.07.2006 №
      152-ФЗ «О персональных данных» отдельное согласие Пользователя на
      обработку его персональных данных не требуется. В силу п.п. 2 п. 2 статьи
      22 указанного закона Оператор вправе осуществлять обработку персональных
      данных без уведомления уполномоченного органа по защите прав субъектов
      персональных данных.&nbsp;
      <br />
      Если Пользователь считает, что какая-либо информация, которую Оператор
      хранит/использует некорректная или неполная, Пользователь вправе войти в
      свою учетную запись и исправить информацию самостоятельно.
      <br />
      Если это предусмотрено применяемым законодательством, Пользователь вправе:
      <br />
      <ol>
        <li>
          требовать удаления персональной информации или ее части, а также
          отзывать согласие на обработку персональной информации;
        </li>
        <li>требовать ограничений на обработку персональной информации;</li>
        <li>
          возражать против обработки персональной информации, если это
          предусмотрено применимым законодательством.
        </li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">
        Хранение и использование персональных данных
      </h4>
      <ol>
        <li>
          Персональные данные Пользователей будут обрабатываться и храниться в
          Российской Федерации на Яндекс.Облаке.
        </li>
        <li>
          Персональные данные пользователей хранятся исключительно на
          электронных носителях и обрабатываются с использованием
          автоматизированных систем, за исключением случаев, когда
          неавтоматизированная обработка персональных данных необходима в связи
          с исполнением требований законодательства.
        </li>
        <li>
          Оператор принимает все необходимые меры для защиты персональных данных
          Пользователя от неправомерного доступа, изменения, раскрытия или
          уничтожения. Оператор предоставляет доступ к персональным данным
          Пользователя только тем Пользователям/лицам, которым эта информация
          необходима с целью исполнения назначения Сервиса и для обеспечения
          функционирования Сервиса, а также иных действий, незапрещённых
          российским законодательством.&nbsp;
        </li>
        <li>
          Оператор вправе использовать предоставленную Пользователем информацию,
          в том числе персональные данные, в целях обеспечения соблюдения
          требований действующего законодательства Российской Федерации (в том
          числе в целях предупреждения и/или пресечения незаконных и/или
          противоправных действий Пользователей).&nbsp;
        </li>
        <li>
          Раскрытие предоставленной Пользователем информации может быть
          произведено лишь в соответствии с действующим законодательством
          Российской Федерации по требованию суда, правоохранительных органов, а
          равно в иных предусмотренных законодательством Российской Федерации
          случаях.&nbsp;
        </li>
        <li>
          Пользователь уведомлен и согласен с тем, что в целях контроля качества
          Оператор вправе проводить исследования переданной и полученной
          Пользователем информации в обезличенном агрегированном виде без
          возможности отнести такую информацию к прямо или косвенно
          определенному или определяемому Пользователю.&nbsp;
        </li>
        <li>
          Оператор будет хранить персональные данные столько времени, сколько
          это необходимо для достижения цели, для которой она была собрана, или
          для соблюдения требований законодательства и нормативных актов.
        </li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">Передача персональных данных</h4>
      <ol>
        <li>
          Персональные данные Пользователей не передаются каким-либо третьим
          лицам, за исключением случаев, прямо предусмотренных настоящей
          Политикой конфиденциальности и для целей использования Сервиса.
        </li>
        <li>
          Персональные данные Пользователей могут быть переданы третьим лицам
          для осуществления функций платежной системы с условиям принятия
          данными лицами обязательств по сохранению конфиденциальности данных
          Пользователей. Для осуществления платежей используется сервис
          <a href="http://cloudpayments.ru">cloudpayments.ru</a>.
        </li>
        <li>
          В иных случаях при указании Пользователя или при наличии согласия
          Пользователя возможна передача персональных данных Пользователя
          третьим лицам-контрагентам Оператора с условием принятия такими
          контрагентами обязательств по обеспечению конфиденциальности
          полученной информации.
        </li>
        <li>
          Предоставление персональных данных Пользователей по запросу
          государственных органов (органов местного самоуправления)
          осуществляется в порядке, предусмотренном законодательством.
        </li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">Уничтожение персональных данных</h4>
      Персональные данные пользователя уничтожаются при:
      <br />
      <ol>
        <li>
          самостоятельном удалении Пользователем данных из своего Личного
          кабинета;
        </li>
        <li>
          удалении Администрацией Сервиса информации, размещаемой Пользователем,
          а также Личного кабинета Пользователя в случаях, установленных
          установленных действующим законодательством.
        </li>
      </ol>
      В случае удаления Личного кабинета Оператор хранит на своих электронных
      носителях персональные и иные необходимые данные Пользователя в течение
      необходимого и установленного действующим законодательством Российской
      Федерации срока.&nbsp;
      <br />
      <br />
      <h4 className="t-redactor__h4">Прочие условия</h4>
      <ol>
        <li>
          Настоящая Политика конфиденциальности может быть изменена Оператором
          без какого-либо специального уведомления, новая редакция Политики
          конфиденциальности вступает в силу с момента ее размещения в сети
          Интернет по указанному в настоящем пункте адресу, если иное не
          предусмотрено новой редакцией Политики конфиденциальности. Действующая
          редакция Политики конфиденциальности находится на странице по адресу:
          <a href="https://life-air.org/tpost/zhmfifxksk-politika-konfidentsialnosti-servisa-life">
            https://life-air.org/tpost/zhmfifxksk-politika-konfidentsialnosti-servisa-life
          </a>
          , если в Политике конфиденциальности не указано иное.
        </li>
        <li>
          Настоящая Политика конфиденциальности регулируется и толкуется в
          соответствии с законодательством Российской Федерации. По всем
          вопросам, не урегулированным настоящей Политикой конфиденциальности,
          стороны руководствуются действующим законодательством Российской
          Федерации.&nbsp;
        </li>
        <li>
          Если по каким-либо причинам одно или несколько условий настоящей
          Политики конфиденциальности будут признаны недействительными или не
          имеющими юридической силы, это не окажет влияния на действительность
          или применимость остальных условий Политики конфиденциальности.
        </li>
        <li>
          В случае если Оператором были внесены какие-либо изменения в настоящую
          Политику конфиденциальности, с которыми Пользователь не согласен, он
          обязан прекратить использование Сервиса.
        </li>
        <li>
          Пользователь предоставляет Оператору право направлять Пользователю на
          электронную почту или номер мобильного телефона информационные и
          рекламные сообщения о запланированных Консультациях, результатах
          Консультаций, возможности и стоимости получения Консультаций, а также
          о порядке использования Сервиса. Пользователь осведомлен, что вправе
          отказаться от получения сообщений в любой момент, уведомив Оператора с
          использованием указанных адреса электронной почты или номера
          мобильного телефона Пользователя.&nbsp;
        </li>
      </ol>
    </section>
  </div>
);
