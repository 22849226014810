import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { Api } from "../../api/api";
import { SocketContext } from "../../context/socket";
import PatientCard from "./components/Card";
import Panel from "../../components/Panel/Panel";
import style from "./order.module.scss";
import { Button, Attention, Card } from "../../components";
import { useStores } from "../../models";
import {
  IoWalletOutline,
  IoCardOutline,
  IoCheckmarkCircleOutline,
  IoBanSharp,
} from "react-icons/io5";

const formattedDate = (date) => {
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  const year = String(date.getFullYear());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${day}.${month}.${year}`;
};

export const ConfirmOrder = observer(() => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { orderStore, referralStore, placeStore, userStore } = useStores();
  const { currentOrder } = orderStore;
  const language = i18n.language;
  const socket = useContext(SocketContext);

  useEffect(() => {
    let mounted = true;
    window.scrollTo(0, 0);
    socket.on("connect", () => {
      console.log("connected");
    });
    if (mounted) {
      const loadData = async () => {
        await orderStore.getOrderById(id);
        await placeStore.getPlaces();
        await referralStore.getReferralsByOrderId(id);
      };
      loadData().then(() => orderStore.setCurrentOrder(id));
    }

    return () => {
      mounted = false;
      orderStore.setCurrentOrder(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    socket.on("REFERRAL_ADD", (referral) => referralStore.add(referral));
    socket.on("ORDER_PAID", ({ id }) => orderStore.paid(id));
  }, [orderStore, referralStore, socket]);

  if (!currentOrder?.patients) {
    return (
      <div className={style.loader}>
        <p>{t("Loading")}</p>
      </div>
    );
  }

  const currentProduct = (patient) => {
    return language === "ru" ? patient.product?.name : patient.product?.nameEn;
  };

  const currentProducts = (patient) => {
    return patient.products.map((product) =>
      language === "ru" ? product?.name : product?.nameEn,
    );
  };

  const isOnline = currentOrder?.paymentMethod === "online";

  const redirectToPay = async () => {
    let getLink = await new Api().pay(id);
    window.location.assign(getLink.data.paymentUrl);
  };

  const handleCloudPaymentClick = () => {
    const itemsForCheck = [];
    for (let i = 0; i < currentOrder.patients.length; ++i) {
      const countSamplings = {};
      let patient = currentOrder.patients[i];
      patient.products.forEach((product) => {
        let price = product.price;
        countSamplings[product.samplingCode] =
          countSamplings[product.samplingCode] + 1 || 1;
        if (countSamplings[product.samplingCode] > 1) {
          price = price - 250;
        }
        itemsForCheck.push({ name: product.nameForCheck, price, quantity: 1 });
      });
    }
    const itemsForCheckUniq = itemsForCheck.reduce((acc, curr) => {
      const dublicates = acc.filter(
        (item) => item.name === curr.name && item.price === curr.price,
      );
      if (dublicates.length === 0) {
        acc.push(curr);
      }
      if (dublicates.length > 0) {
        const idx = acc.findIndex((item) => item.name === curr.name && item.price === curr.price)
        acc[idx] = { ...curr, quantity: acc[idx].quantity + 1 };
      }
      return acc;
    }, []);

    const items = [];

    for (let item of itemsForCheckUniq) {
      items.push({
        label: item.name,
        quantity: item.quantity,
        amount: item.price * item.quantity,
        price: item.price,
        measurementUnit: "шт",
        vat: null,
        method: 4,
        object: 4,
      });
    }

    if (!items?.length) return;
    const itemsPrice = items
      .map((item) => item.amount)
      .reduce((acc, cur) => acc + cur, 0);

    if (itemsPrice !== currentOrder.sum) {
      console.log("items", itemsPrice, currentOrder.sum)
      alert("Цена отличается");
      return;
    }
    const receipt = {
      Items: items,
      taxationSystem: 0,
      amounts: { electronic: currentOrder.sum },
      calculationPlace: "labarhimed.ru",
    };
    const data = {
      //содержимое элемента data
      cloudPayments: {
        CustomerReceipt: receipt, //онлайн-чек
      },
      comment: currentOrder?.place?.name,
    };
    if (currentOrder.sum === 0) {
      alert(
        "Стоимость заказа не может быть 0, возможно в данном месте возможен только иной метод оплаты.",
      );
      return;
    }
    if (!currentOrder.sum || !currentOrder.id || !data) return;
    window.pay({
      amount: currentOrder.sum,
      description: `Оплата теста на COVID - ${currentOrder.id}`,
      invoiceId: currentOrder.id,
      email: userStore.user.email,
      publicId:
        currentOrder.place?.cloudPublicId || "pk_e0a80bdd39b9320671d689c4051a8",
      data,
    });
  };

  return (
    <div className={style.main}>
      <Helmet>
        <script
          src="https://widget.cloudpayments.ru/bundles/cloudpayments"
          type="text/javascript"
        />
      </Helmet>
      <div className={style.mainInner}>
        <Attention />
        <Panel
          title={`${t("proof_order")}: №${currentOrder.id} ${t(
            "at",
          )} ${formattedDate(new Date(currentOrder.createdAt))}`}
          withoutSpoiler
        >
          <React.Fragment>
            <div className={style.order}>
              <div className={style.placeTestTitle}>{t("testPlaceOrder")}:</div>
              <Card
                place={currentOrder.place}
                className={style.place}
                showInfoFull
              />
            </div>
            <div className={style.patients}>
              <div className={style.patientsTitle}>{t("patients")}:</div>
              <div className={style.patientsList}>
                {currentOrder.patients &&
                  currentOrder.patients.map((patient, index) => (
                    <PatientCard
                      patient={{
                        ...patient,
                        product: currentProduct(patient),
                        products: currentProducts(patient),
                        referral: patient.referral,
                      }}
                      index={index}
                      key={patient.id}
                    />
                  ))}
              </div>
            </div>
            <div className={style.paymentMethod}>
              <div className={style.name}>{t("price")}:</div>
              {currentOrder.sum} &#8381;
            </div>
            <div className={style.paymentMethod}>
              <div className={style.name}>{t("paymentMethod")}:</div>
              {currentOrder && currentOrder?.paymentMethod !== "online" ? (
                <IoWalletOutline size="1.5em" />
              ) : (
                <IoCardOutline size="1.5em" />
              )}
              &nbsp;
              {t(currentOrder && currentOrder.paymentMethod)}
            </div>
            <div className={style.paymentMethod}>
              <div className={style.name}>{t("paid")}:</div>
              {currentOrder && currentOrder?.paid ? (
                <IoCheckmarkCircleOutline size="1.5em" />
              ) : (
                <IoBanSharp size="1.5em" />
              )}
              &nbsp;
              {currentOrder && currentOrder.paid ? t("yes") : t("no")}
            </div>
            {isOnline && !currentOrder?.paid ? (
              <>
                {currentOrder.place?.robokassa && (
                  <Button uitype="green" onClick={redirectToPay}>
                    {t("agree_and_pay")} Robokassa
                  </Button>
                )}
                {currentOrder.place?.cloudPayments && (
                  <Button uitype="green" onClick={handleCloudPaymentClick}>
                    {t("agree_and_pay")} CloudPayments
                  </Button>
                )}
              </>
            ) : (
              <Button uitype="green" onClick={() => history.push("/myorders")}>
                {t("agree_order")}
              </Button>
            )}
          </React.Fragment>
        </Panel>
      </div>
    </div>
  );
});
