import { types } from "mobx-state-tree";
import { OrderStore } from "./OrderStore";
import { PatientStore } from "./PatientStore";
import { PlaceStore } from "./PlaceStore";
import { UserStore } from "./UserStore";
import { ToastStore } from "./ToastStore";
import { ReferralStore } from "./ReferralStore";
import { ProductStore } from "./ProductStore";

export const RootStoreModel = types.model("RootStore").props({
  orderStore: types.optional(OrderStore, {}),
  patientStore: types.optional(PatientStore, {}),
  placeStore: types.optional(PlaceStore, {}),
  userStore: types.optional(UserStore, {}),
  productStore: types.optional(ProductStore, {}),
  toastStore: types.optional(ToastStore, {}),
  referralStore: types.optional(ReferralStore, {}),
});

export const RootStore = RootStoreModel;
