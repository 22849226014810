import React from "react";
import { useTranslation } from "react-i18next";

import style from "./attention.module.scss";

const Attention = () => {
  const { t } = useTranslation();
  return (
    <div className={style.attention}>
      <div className={style.leftPart}>
        <div className={style.attentionWarning}>{t("attention")}!</div>
        <div className={style.notemperatureLeft}>
          {t("left_no_temperature")}
        </div>
      </div>
      <div className={style.rightPart}>
        <div className={style.before}>{t("before")}</div>
        <div className={style.txt}>{t("no_smoke")}</div>
        <div className={style.txt}>{t("no_temperature")}</div>
      </div>
    </div>
  );
};

export default Attention;
