import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import style from "./switcher.module.scss";
import { useFormikContext } from "formik";

export const PAY_METHODS = {
  online: "online",
  offline: "offline",
};

export const Switcher = ({ onlyOnlinePayment = false }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext();
  const changeChoosen = (value) => setFieldValue("paymentMethod", value);
  const classNameGen = (index) =>
    cn(style.switchEl, {
      [style["switchEl--choosen"]]: index !== values.paymentMethod,
    });
  React.useEffect(() => {
    if (onlyOnlinePayment === true)
      setFieldValue("paymentMethod", PAY_METHODS.online);
  }, [onlyOnlinePayment, setFieldValue]);
  return (
    <div className={style.switcher}>
      <div
        onClick={() => changeChoosen(PAY_METHODS.online)}
        className={classNameGen(PAY_METHODS.online)}
      >
        {t("online")}
      </div>
      {!onlyOnlinePayment && (
        <div
          onClick={() => changeChoosen(PAY_METHODS.offline)}
          className={classNameGen(PAY_METHODS.offline)}
        >
          {t("offline")}
        </div>
      )}
    </div>
  );
};
