import React from "react";
import socketio from "socket.io-client";
const { REACT_APP_API } = process.env;

const storage = localStorage.getItem("root");
const username = storage ? JSON.parse(storage).userStore.user.username : null;
const connection = socketio.connect(REACT_APP_API);
connection.auth = { username: username };
export const socket = connection
export const SocketContext = React.createContext(socket);
