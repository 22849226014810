import { create } from "apisauce";
import { getGeneralApiProblem } from "./getGeneralApiProblem";
const { REACT_APP_API } = process.env;
export class Api {
  constructor() {
    this.setup();
  }
  setup() {
    const storage = localStorage.getItem("root");
    const token = storage ? JSON.parse(storage).userStore.user.jwt : null;
    this.apisauce = create({
      baseURL: REACT_APP_API,
      timeout: 10000,
      headers: {
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
  }


  async pay(id) {
    const response = await this.apisauce.get(`/orders/pay/${id}`);
    try {
      return { kind: "ok", data: response.data };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async getMe() {
    const response = await this.apisauce.get("/users/me");

    try {
      return { kind: "ok", data: response };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async sendCode(username, email) {
    const response = await this.apisauce.post("/auth/sms-login", {
      username,
      email: email,
    });

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    try {
      return { kind: "ok", data: response };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async checkCode(params) {
    try {
      const result = await this.apisauce.post("/auth/sms-login", params);
      if (result.ok) {
        return {
          kind: "ok",
          data: result.data,
        };
      }
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async createOrder(order) {
    const response = await this.apisauce.post("/orders", order);

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const convertOrder = (raw) => {
      return {
        id: raw.id.toString(),
        place: String(raw.place),
        patients: raw.patients,
        paymentMethod: raw.paymentMethod,
        paid: raw.paid,
        createdAt: raw.created_at,
        sum: raw.sum,
        documentTypeId: raw.documentTypeId,
        series: raw.series,
        num: raw.num,
        issueDate: raw.issueDate,
        issuedBy: raw.issuedBy,
      };
    };

    const rawOrder = response.data;
    const resultOrder = convertOrder(rawOrder);

    try {
      return { kind: "ok", order: resultOrder };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async getOrders() {
    const response = await this.apisauce.get("/orders");
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const convertOrder = (raw) => {
      return {
        id: raw.id.toString(),
        patients: raw.patients,
        paymentMethod: raw.paymentMethod,
        place: String(raw.place) || "",
        paid: raw.paid,
        createdAt: raw.created_at,
        sum: raw.sum,
      };
    };

    try {
      const rawOrders = response.data;
      const resultOrders = rawOrders.map(convertOrder);
      return { kind: "ok", orders: resultOrders };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async getOrderById(id) {
    const response = await this.apisauce.get(`/orders/${id}`);
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const convertOrder = (raw) => {
      return {
        id: raw.id.toString(),
        patients: raw.patients,
        paymentMethod: raw.paymentMethod,
        place: String(raw.place) || "",
        paid: raw.paid,
        createdAt: raw.created_at,
        sum: raw.sum,
      };
    };

    try {
      const rawOrders = response.data;
      const resultOrder = convertOrder(rawOrders);
      return { kind: "ok", order: resultOrder };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async getRefferals() {
    const response = await this.apisauce.get("/referrals");

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const convertReferral = (raw) => {
      return {
        id: raw.id.toString(),
        referralId: raw.referralId.toString(),
        patient: raw.patient.id.toString(),
        patientRaw: raw.patient,
      };
    };

    try {
      const rawReferrals = response.data;
      const resultReferrals = rawReferrals.map(convertReferral);
      return { kind: "ok", referrals: resultReferrals };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async getReferralById(id) {
    const response = await this.apisauce.get(`/referrals/${id}`);

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    const convertReferral = (raw) => {
      return {
        id: raw.id.toString(),
        referralId: raw.referralId.toString(),
        patient: raw.patient.toString(),
      };
    };

    try {
      const rawReferrals = response.data;
      const resultReferral = convertReferral(rawReferrals);
      return { kind: "ok", referral: resultReferral };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async getReferralsByOrderId(id) {
    const response = await this.apisauce.get(`orders/${id}/referrals`);

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    const convertReferral = (raw) => {
      return {
        id: raw.id.toString(),
        referralId: raw.referralId.toString(),
        patient: raw.patient.toString(),
      };
    };

    const rawReferrals = response.data;
    // if (Array.isArray(rawReferrals) && !rawReferrals[0]) return

    try {
      const resultReferrals = rawReferrals.map(convertReferral);
      return { kind: "ok", referrals: resultReferrals };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }

  async getPlaces() {
    // make the api call
    const response = await this.apisauce.get(`/places`);

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const convertPlace = (raw) => {
      return {
        id: raw.id.toString(),
        name: raw.name,
        nameEn: raw.nameEn,
        onlyTerminal: raw.onlyTerminal || false,
        infoRus: raw.infoRus || "",
        infoEng: raw.infoEng || "",
        infoFullRus: raw.infoFullRus || "",
        infoFullEng: raw.infoFullEng || "",
        products: raw.products,
        show: raw.show,
        onlyOnlinePayment: raw.onlyOnlinePayment,
        robokassa: raw.Robokassa,
        cloudPayments: raw.CloudPayments,
        cloudPublicId: raw.cloudPublicId,
      };
    };
    // transform the data into the format we are expecting
    try {
      const rawPlaces = response.data;
      const resultPlaces = rawPlaces.map(convertPlace);
      return { kind: "ok", places: resultPlaces };
    } catch (err) {
      return { kind: "bad-data", err };
    }
  }
}
