/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ReactSVG } from "react-svg";
import { NavLink, useHistory, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import style from "./header.module.scss";
import logo from "./logo.svg";
import List from "./list.svg";
import Arrow from "../ChangeLanguage/Arrow.svg";
import { useStores } from "../../models";
import { observer } from "mobx-react";

export const Header = observer(({ changeModal }) => {
  const { userStore } = useStores();
  const headerListRef = React.useRef();
  const [show, toggleShow] = React.useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [showUserMenu, toggleShowUserMenu] = React.useState(false);
  const location = useLocation();
  const handleClick = React.useCallback(
    ({ target }) => {
      if (!headerListRef.current) return;
      if (!headerListRef.current.contains(target)) {
        toggleShow(false);
      }
    },
    [show],
  );
  React.useEffect(() => {
    toggleShow(false);
  }, [location]);
  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [show]);
  return (
    <header className={style.headerWrapper}>
      <div className="container">
        <div className={style.header}>
          <img
            src={logo}
            alt="logo"
            className={style.logo}
            onClick={() => history.push("/")}
          />
          <div className={style.mdV}>
            <div className={style.leftPart}>
              <div className={style.links}>
                <NavLink className={style.link} exact to="/">
                  {t("how_it_works")}
                </NavLink>
                <NavLink className={style.link} exact to="/order">
                  {t("take_a_test")}
                </NavLink>
              </div>
              <div className={style.rightPart}>
                <ChangeLanguage />
                {userStore.user.username ? (
                  <div
                    className={style.user}
                    onClick={() => toggleShowUserMenu(!showUserMenu)}
                  >
                    <div className={style.number}>
                      <ReactSVG
                        src={Arrow}
                        className={cn(style.arrowImg, {
                          [style["arrowImg--rotate"]]: showUserMenu,
                        })}
                      />
                      {userStore.user.username}
                    </div>
                    {showUserMenu && (
                      <div className={style.subMenu}>
                        <Link className={style.subMenu_item} to="/myorders">
                          {t("account")}
                        </Link>
                        <div
                          className={style.subMenu_item}
                          onClick={userStore.clearUser}
                        >
                          {t("logout")}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={style.login}
                    onClick={() =>
                      changeModal({ isOpen: true, name: "LOG_IN" })
                    }
                  >
                    {t("log_in")}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={style.smV}>
            <div className={style.smVTop}>
              <ChangeLanguage />
              <ReactSVG
                src={List}
                onClick={() => toggleShow(!show)}
                className={show ? style.rotate : ""}
              />
            </div>
            {show && (
              <div className={style.list} ref={headerListRef}>
                <div className={style.leftPart}>
                  <div className={style.links}>
                    <NavLink className={style.link} exact to="/">
                      {t("how_it_works")}
                    </NavLink>
                    {/* <NavLink className={style.link} exact to="/faq">
                      {t("faq")}
                    </NavLink> */}
                    <NavLink className={style.link} exact to="/order">
                      {t("take_a_test")}
                    </NavLink>
                  </div>
                  <div className={style.rightPart}>
                    {userStore.user.username ? (
                      <div>
                        <div className={style.link}>
                          {userStore.user.username}
                        </div>
                        <Link className={style.link} to="/myorders">
                          {t("account")}
                        </Link>
                        <div
                          className={style.link}
                          onClick={userStore.clearUser}
                        >
                          {t("logout")}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={style.login}
                        onClick={() =>
                          changeModal({ isOpen: true, name: "LOG_IN" })
                        }
                      >
                        {t("log_in")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
});
