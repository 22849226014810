import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import rus from "./rus.json";
import eng from "./eng.json";
import zh from "./zh.json";

export const getLanguage = () => {
  const language = window.navigator.language.slice(0, 2).toLowerCase();
  switch (language) {
    case "ru":
      return "ru";
    case "en":
      return "en";
    case "uz":
      return "ru";
    case "tg":
      return "ru";
    case "kk":
      return "ru";
    case "uk":
      return "ru";
    case "be":
      return "ru";
    case "hy":
      return "ru";
    case "ro":
      return "ru";
    case "az":
      return "ru";
    case "ky":
      return "ru";
    case "zh":
      return "zh";
    default:
      return "en";
  }
};

const resources = {
  en: {
    translation: eng,
  },
  ru: {
    translation: rus,
  },
  zh: {
    translation: zh,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lang") || getLanguage(),

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
