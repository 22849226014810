import React from "react";
import { useTranslation } from "react-i18next";

import styleHome from "../../home.module.scss";
import style from "./questionsSection.module.scss";
import { Question } from "../Question";

export function QuestionsSection() {
  const { t } = useTranslation();

  const questions = [
    {
      title: t("landing.questions.question_1"),
      answer: t("landing.questions.answer_1"),
    },
    {
      title: t("landing.questions.question_2"),
      answer: t("landing.questions.answer_2"),
    },
    {
      title: t("landing.questions.question_3"),
      answer: t("landing.questions.answer_3"),
    },
    {
      title: t("landing.questions.question_4"),
      answer: t("landing.questions.answer_4"),
    },
    {
      title: t("landing.questions.question_5"),
      answer: t("landing.questions.answer_5"),
    },
  ];

  return (
    <div className={`${styleHome["st-section"]} ${style["questions-section"]}`}>
      <div className="container">
        <div className={styleHome["st-section__head"]}>
          <div className="title-site title-site_h2">
            {t("landing.questions.title")}
          </div>
        </div>
        <ul className={style.questions}>
          {questions.map((item, index) => (
            <Question key={index} question={item} />
          ))}
        </ul>
      </div>
    </div>
  );
}
