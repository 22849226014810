import React from "react";
import {
  MainSection,
  ServicesSection,
  StepsSection,
  QuestionsSection,
} from "./components";

export const Home = () => {
  return (
    <div>
      <MainSection />
      <ServicesSection />
      <StepsSection />
      <QuestionsSection />
    </div>
  );
};
