import React from "react";
import { Button } from "../../components";
import style from "./ErrorBoundary.module.scss";
import LogRocket from "logrocket";
import { withTranslation } from "react-i18next";
import { captureException } from "@sentry/browser";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, try: 0 };
  }

  componentDidCatch(error, info) {
    console.log("ErrorBoundary", error, info);

    this.setState({ hasError: true });
    captureException(error, info);
    LogRocket.captureException(error, info);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.children.props.location.pathname !==
      prevProps.children.props.location.pathname
    ) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      console.log("this.state.hasError", this.state.hasError);

      const refreshPage = () => {
        let count = this.state.try;
        this.setState({ hasError: false, try: ++count });
      };
      // You can render any custom fallback UI
      return (
        <div className={style.container}>
          <h3>Something went wrong. Что-то пошло не так.</h3>
          {this.state.try < 1 && (
            <Button onClick={refreshPage}>{this.props.t("refreshPage")}</Button>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
