import React from "react";
import { useTranslation } from "react-i18next";
import style from "./failure.module.scss";

export const Failure = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className={style["failure-inner"]}>
        <div className={style["failure-main"]}>
          <div className={style["failure-title"]}>
            {t("failure_screen.title")}
          </div>
          <div className={style["failure-message"]}>
            {t("failure_screen.message")}
          </div>
        </div>
      </div>
    </div>
  );
};
