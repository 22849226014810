import React from "react";
import { useTranslation } from "react-i18next";
import Barcode from "react-barcode";
import { getDocNameById } from "../../../../components/Forms/Passport";
import style from "./card.module.scss";

const NEED_PROPS = [
  "lastName",
  "firstName",
  "middleName",
  "firstNameLastNameEng",
  {
    name: "dateOfBirth",
    humanize: () => '**.**.****',
  },
  // "passport",
  "address",
  "workOrStudyPlace",
  "flightNumber",
  "product",
  {
    name: "sex",
    humanize: (value, t) => t(value),
  },
  {
    name: "documentTypeId",
    humanize: (value, t) => t(getDocNameById(value)),
  },
  "series",
  "num",
  "issueDate",
  "issuedBy",
  // {
  //   name: "products",
  //   humanize: (products) =>
  //     products.map((product) => product).join(", "),
  // },
];

const pickAndSortProps = (patient) => {
  return NEED_PROPS.map((item) => {
    if (typeof item === "object") {
      return { [item.name]: item.humanize };
    }
    return { [item]: patient[item] };
  });
};

const ProductListItem = ({ product }) => {
  return (
    <div className={style.product}>
      {product}
    </div>
  );
};

const PatientProducts = ({ products }) => {
  //return products as product list in every row
  return products.map((product) => <ProductListItem key={product} product={product}/>);


};

const PatientCard = ({ patient, index }) => {
  const { t } = useTranslation();
  const pickedArray = pickAndSortProps(patient);
  const referralId = patient?.referral?.referralId;

  return (
    <div className={style.card}>
      <div className={style.title}>
        {t("patient")} №{index + 1} {t("forTest")}
      </div>
      <div>{referralId && <Barcode width={2} value={referralId} />}</div>
      <div className={style.info}>
        {pickedArray.map((item) => {
          const key = Object.keys(item)[0];
          const value =
            typeof item[key] === "function"
              ? item[key](patient[key], t)
              : item[key];
          return (
            value && (
              <div className={style.infoElement} key={`${key}-${value}`}>
                <div className={style.infoKey}>{t(key)}:</div>
                <div className={style.infoValue}>{value}</div>
              </div>
            )
          );
        })}
        <PatientProducts products={patient.products}/>
      </div>
    </div>
  );
};

export default PatientCard;
