import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { useStores } from "../../models";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export const Toast = observer(() => {
  const { toastStore } = useStores();
  const { toasts } = toastStore;
  const { t } = useTranslation();

  React.useEffect(() => {
    if (toastStore.showMessage) {
      switch (toastStore.showMessage.type) {
        case "error":
          toast.error(t(toastStore.showMessage.message));
          break;
        case "success":
          toast.success(t(toastStore.showMessage.message));
          break;
        default:
          toast(t(toastStore.showMessage.message));
      }
    }
    toasts.remove();
    return () => null;
  }, [t, toastStore, toasts, toasts.length]);
  return <ToastContainer />;
});

export default Error;
