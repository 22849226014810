import React from "react";

import style from "./checkbox.module.scss";

export const Checkbox = ({ onChange, checked, children }) => (
  <label className={style.container}>
    {children}
    <input
      type="checkbox"
      className={style.checkbox}
      checked={checked}
      onChange={onChange}
    />
    <span className={style.checkmark}></span>
  </label>
);
