export function getGeneralApiProblem(response) {
  switch (response.problem) {
    case "CONNECTION_ERROR":
      return { kind: "cannot-connect", temporary: true, data: response };
    case "NETWORK_ERROR":
      return { kind: "cannot-connect", temporary: true, data: response };
    case "TIMEOUT_ERROR":
      return { kind: "timeout", temporary: true, data: response };
    case "SERVER_ERROR":
      return { kind: "server" };
    case "UNKNOWN_ERROR":
      return { kind: "unknown", temporary: true, data: response };
    case "CLIENT_ERROR":
      switch (response.status) {
        case 401:
          return { kind: "unauthorized" };
        case 403:
          return { kind: "forbidden" };
        case 404:
          return { kind: "not-found" };
        case 429:
          return {
            kind: "Too Many Requests",
            data: "Too Many Requests / Превышен лимит запросов",
          };
        default:
          return { kind: "rejected", data: response.data.message };
      }
    case "CANCEL_ERROR":
      return null;
    default:
      return null;
  }
}
