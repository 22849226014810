import { types, flow, getRoot } from "mobx-state-tree";
import { PlaceModel } from "./PlaceModel";

import { Api } from "../api/api";

export const PlaceStore = types
  .model("PlaceStore", {
    places: types.optional(types.array(PlaceModel), []),
    pending: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    afterCreate() {
      self.pending = true;
      // if (self.places.length > 0) return
      this.getPlaces();
    },
    savePlaces(places) {
      self.places = [];
      places.forEach((place) => {
        place.products.forEach((product) => {
          getRoot(self).productStore.add({
            ...product,
            id: product.id.toString(),
          });
        });
      });
      const placesModels = places.map((c) =>
        PlaceModel.create({
          ...c,
          products: c.products.map((p) => p.id.toString()),
        }),
      );
      self.places.replace(placesModels);
    },
    getPlaces: flow(function* getPlaces() {
      const result = yield new Api().getPlaces();
      if (result.kind === "ok") {
        self.savePlaces(result.places);
        self.pending = false;
      } else {
        self.pending = false;
        getRoot(self).toastStore.addError(result.kind);
      }
    }),
  }));
