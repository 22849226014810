import React from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import style from "./form.module.scss";

import { Switcher, Button, Input } from "../";
import { totalForPatientWithDiscount, countDiscountForPatient } from "../../models/PatientModel";

export const PayChoose = ({
  patients = [],
  onNextStep,
  isValid,
  products,
  onlyOnlinePayment,
  offline,
  isPending,
}) => {
  const { t } = useTranslation();
  const [code, changeCode] = React.useState(null);
  const { setFieldValue, values } = useFormikContext();

  React.useEffect(() => {
    setFieldValue("paymentMethod", offline);
  }, [offline, setFieldValue]);

  React.useEffect(() => {
    setFieldValue("code", code);
  }, [code, setFieldValue]);

  const isActiveButton = () => {
    return offline === "offline" ? isValid && code?.length > 13 : isValid;
  };

  if (!patients || !products) return null;

  const selectedProductsIds = patients
    ?.map((patient) => patient.product)
    .flat();

  const selectedProducts = selectedProductsIds.map((id) =>
    products.find((product) => product.id === id),
  );

  const patientsProducts = patients.map((patient) =>
    patient.product
      .map((productId) => products.find((p) => p.id === productId))
      .filter(Boolean),
  );

  const totalPriceWithDiscount = patientsProducts
    .map(totalForPatientWithDiscount)
    .reduce((acc, cur) => acc + cur, 0);
  const totalDiscount = patientsProducts
    .map(countDiscountForPatient)
    .reduce((acc, cur) => acc + cur, 0);
  const totalPriceByPatient = patientsProducts.map((products) =>
    products.reduce((acc, cur) => acc + cur.price, 0),
  );

  let acc = {};
  let sum = 0;

  for (let product of selectedProducts) {
    if (!product) break;
    acc[product.price] =
      acc[product.price] === undefined
        ? 1
        : (acc[product.price] = acc[product.price] + 1);
    sum = sum + product.price;
  }
  return (
    <div className={style.wrapper}>
      <div className={style.payChoose}>
        {offline !== "offline" ? (
          <div className={style.mgRight50}>
            <Switcher
              onlyOffline={offline}
              onlyOnlinePayment={onlyOnlinePayment}
            />
            <p className={style.econom}>{t("onlineTimeEconom")}</p>
          </div>
        ) : (
          <Input
            name="code"
            label={t("code")}
            className={style.input}
            mask="9999-9999-9999"
            onChange={changeCode}
            value={values.code}
          />
        )}
        <div className={style.price}>
          {totalPriceByPatient.map((price, i) => (
            <div key={i} className={style.p}>{`${price} ${
              i === totalPriceByPatient.length - 1 ? "" : "+"
            }`}</div>
          ))}
          {!!totalDiscount && <div>- {totalDiscount}</div>}
          <div>= {totalPriceWithDiscount}</div>
        </div>
        <Button
          uitype={(isActiveButton() && !isPending) ? "green" : "disabled"}
          onClick={onNextStep}
        >
          {t("nextStep")}
        </Button>
      </div>
    </div>
  );
};
