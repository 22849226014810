import { types } from "mobx-state-tree";

export const UserModel = types
  .model("User", {
    jwt: types.optional(types.string, ""),
    username: types.optional(types.string, ""),
    email: types.optional(types.string, ""),
  })
  .actions((self) => ({
    isAuthorized() {
      return self.jwt;
    },
  }));
