import React from "react";

import style from "./politics.module.scss";

export const TermOfUseEng = () => (
  <div className={style.wrap}>
    <section className={style.section}>
      <h1>Term Of Use</h1>
      The «Клиника Архимед» service (the Service) is designed to support online
      registration of a patient (the User) for an appointment with the relevant
      medical organization/laboratory to schedule a test (the Medical Service)
      and get the result using the functionality offered in the Service.
      <br />
      The Service is a web application for mobile devices and computers that
      allows authorized Users to order the Medical Service online and, with the
      use of the Service, support the processing of payment for the Medical
      Service provided.
      <br />
      Before you start using the Service, please read the Terms of Use. The fact
      of using the Service in any manner, both intentionally or unintentionally,
      is treated as acceptance, i.e. the complete and unconditional consent of
      the User to use the Service on the conditions set out below. The use of
      the Service is only possible under the terms and conditions of the Terms
      of Use. If the User does not fully accept the provisions of the Terms of
      Use, the User is not allowed to use the Service for any purpose.
      <br />
      <br />
      <br />
      <h2 className="t-redactor__h2">General Information</h2>
      <ol>
        <li>
          The Terms of Use on the use of the Service (the The Terms of Use) is a
          public offer of ARHIMED CLINIC LLC, INN (TIN) 5003055720, address: 68
          Vavilova St, building 2, Moscow 119261, email: info@arhimedlab.ru,
          tel.: +7 (499) 288 07 26 (the Operator). Under the public offer, the
          Operator provides to registered Users services on online registration
          for an appointment with the relevant medical organization/laboratory
          to schedule a test and obtain the test result in the form of a medical
          report with the use of the functionality of the Service, as well as
          services of transferring to these organizations funds paid for the
          Medical Services rendered to the Users in the manner and on the terms
          and conditions set forth in the Terms of Use.
        </li>
        <li>
          The Terms of Use is a legally binding the Terms of Use between the
          User and the Operator. The subject of the the Terms of Use is the
          provision of access to the Service and its functionality by the
          Operator to the User.&nbsp;
        </li>
        <li>
          The Service allows the Users to apply for paid medical services and do
          other actions supported by the explicit functions of the Service. All
          currently avialable functions of the Service and any their upgrade
          and/or added new functions is the subject of the Terms of Use.
        </li>
        <li>
          The Operator, as a part of the Service operation, does not provide any
          medical or consulting services. The Service provides access to
          authorized medical organizations, convenient communication and payment
          processing for the Users.
        </li>
        <li>
          The Terms of Use come into force on the User's authorization. The User
          authorization in the Service is run on the corresponding page of the
          Operator's website using the login (mobile phone number or email
          address) and the authorization code sent by the Operator to the User
          to the email address or mobile phone number. After authorization, the
          User assigns a password to the User account in the Service. Access to
          the Service is provided by the User's login and password. The Terms of
          Use is valid in relation to the respective User until terminated in
          accordance with the procedure established by the Terms of Use.
        </li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">Payment</h2>
      <ol>
        <li>
          The Medical Services are provided with the use of the Service by
          medical organizations authorized in the Service on the basis of
          contracts for the provision of paid medical services signed with the
          Users. The text of the contract is provided to the User prior to the
          provision of the Medical Service.
        </li>
        <li>
          The Medical Services are provided by medical organizations for a fee.
          The price is determined according to the price list of the medical
          organization made available to the User through the Service.
        </li>
        <li>
          The User pays for the Medical Services through the Service in the form
          of prepayment made in the Service prior to the provision of the
          Medical Service. The Service receives the payment, acts on behalf of
          the User and is authorized by the User to accept funds from another
          User as the payment for the Medical Services, with the involvement of
          an authorized payment receiving operator, or an electronic money
          operator, or other payment and information technology interaction
          parties involved. The Operator does not guarantee that no errors and
          failures will occur in providing non-cash payments.&nbsp;
        </li>
        <li>
          The User pays for the Medical Services with a bank card specified in
          the Service interface (the Linked Card). The Operator is entitled to
          debit the amount of the cost of the Medical Services from any Linked
          Card.&nbsp;
        </li>
        <li>
          The User may also pay for the Medical Service (if the relevant
          function is available to the User) using third-party services that
          support integration with the Service and payment parties involved, and
          provide authorization of the User's payment without transferring
          information about the Linked Card to the Operator. The procedure for
          using the services of third parties specified in this paragraph is
          governed by the rules of such third-party services. The Operator is
          not responsible for the use by the User of third-party services and
          does not guarantee the correctness and safety of such third-party
          services. Payment for the Medical Services may be in the form of a
          single transaction for the full cost of services or series of
          transactions of parts of the cost of the Medical Services.
        </li>
        <li>
          After the provision of the Medical Services, the Operator transfers to
          the account of the relevant medical organization that provided the
          Medical Service, the funds received from the User as payment for the
          Medical Service provided by the medical organization.
        </li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">Use of the Service</h2>The Service is
      provided to the User for personal, non-commercial use.
      <br />
      The Service provides the User with the access to the history of the User's
      activities on the Service.&nbsp;
      <br />
      The User ensures the confidentiality of the login and password requested
      to access the Service. The User bears the sole responsibility for all
      actions of third parties related to the use of the User's account, and all
      such actions are considered to be performed by the User.&nbsp;
      <br />
      In case of detection of unauthorized access to the Services and/or breach
      (suspicion of breach) of the confidentiality of the login or password, the
      User immediately notifies the Operator in order to assign the User a new
      login and/or password.
      <br />
      The User is not entitled to:&nbsp;
      <br />
      <br />
      <ol>
        <li>
          Enter inaccurate personal data, including health-related information,
          in the Service.&nbsp;
        </li>
        <li>
          Enter and/or distribute information, and links to information, the
          distribution of which is prohibited by Russian law.
        </li>
        <li>
          Violate the rights of third parties, including minors, and/or cause
          them damage in any form.
        </li>
        <li>
          Enter and/or distribute information without the required permission or
          consent of third parties subject to law, contract or consent of third
          parties.
        </li>
        <li>
          Enter and/or distribute advertising information not specifically
          permitted, spam or malware (links to them).
        </li>
        <li>
          Collect and store personal data of others without their consent.
        </li>
        <li>Otherwise disrupt the normal operation of the Service.</li>
      </ol>
      The User uses the Service only within the limits of the rights and in the
      manner as stipulated in the Terms of Use.
      <br />
      All graphic and text elements posted on the website and mobile application
      of the Operator or otherwise available for the Users through the Service,
      as well as the software included into the Service configuration are
      objects of the exclusive rights of the Operator and other rightholders.
      Any use of these exclusive rights is allowed only with the consent of the
      Operator or the respective copyright holder. If the User fails to comply
      with this clause, the Operator is entitled to deny access to the Service
      by blocking or deleting the User's account and to delete the information
      posted by the User using the Service.&nbsp;
      <br />
      The Operator is entitled to send advertising and/or informational messages
      to the Users using the Service.&nbsp;
      <br />
      <br />
      <br />
      <h2 className="t-redactor__h2">Limitation of Liability</h2>
      <ol>
        <li>The User uses the Services at his/her own risk.&nbsp;</li>
        <li>
          The Service is provided "as is", the Operator is not responsible for
          the absolute compliance of the Service with the User's requirements.
        </li>
        <li>
          With its functionality ensuring information interaction between the
          Users, the Service does not represent any of the parties, and
          therefore does not give any guarantees regarding the completeness,
          relevance, correctness, reliability of the services received by the
          User.&nbsp;
        </li>
        <li>
          The User bears the sole responsibility for any potential negative
          implications of using the information received by the User with the
          use of the Service.&nbsp;
        </li>
        <li>
          The User is solely responsible for the use of the Service including
          the publication and/or distribution of information, inclusive of if
          such actions lead to the violation of law and/or the rights and
          legitimate interests of third parties.&nbsp;
        </li>
        <li>
          The Operator is not responsible for any loss incurred by the User as a
          result of using the information received by the User through the
          Service. The Operator is also not responsible for failure to comply or
          improper performance of the Terms of Use resulting from failures in
          telecommunications and power supply networks, impact of malicious
          programs and unfair actions of third parties in the form of actions
          aimed at unauthorized access and/or disabling the software and/or
          hardware of the Operator.&nbsp;
        </li>
        <li>
          The Operator bears no responsibility for the correctness of the data
          entered by the User when using the Service and the implications of
          such actions by the User, for the content, correctness and/or
          relevance of information provided by third parties and available with
          the Service and for potential loss of the parties.
        </li>
        <li>
          The liability of the Operator for improper actions of the Operator is
          limited to the cost of the corresponding service.&nbsp;
        </li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">Electronic document management</h2>
      <ol>
        <li>
          The Operator and the User hereby agree that, within the Service, they
          mutually recognize electronic documents signed with an electronic
          signature as equivalent to hard copy documents with a handwritten
          signature.
        </li>
        <li>
          To sign an electronic document with an electronic signature in the
          Service, the User uses the authorizationon function of the Service.
          Codes, logins and passwords are the key of the electronic signature.
        </li>
        <li>The User shall keep electronic signature keys confidential.</li>
        <li>
          The Parties acknowledge that all electronic documents generated, used
          and sent to the Operator by the User through the Service within one
          session (while the user is being authorized in the Service) are thus
          signed by the User's electronic signature. Documents can also be
          signed in the Service by ticking the corresponding document (link to
          the document) by placing a tick next to the document by the User
          within a single session.
        </li>
        <li>
          The parties agree that all actions and electronic documents generated
          and sent to the Operator using the User's electronic signature key are
          considered generated and sent by the User.
        </li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">Other Terms and Conditions</h2>
      <ol>
        <li>
          In the Service, the User gives the consent to the processing of the
          User's personal information by the Operator for the purpose of
          providing the functionality of the Service, to the processing and
          transfer of the User's personal information by the Operator to
          authorized medical organizations (as well as to receive such
          information from them) that provide services to the User, and their
          authorized representatives for further processing of such information
          in order to provide the User with the functionality of receiving
          services from such organizations and in order to provide medical
          assistance, including for medical and preventive purposes, in order to
          make clinical diagnosis, provide healthcare and medical and social
          services provided that the processing of personal data is carried out
          by a person who is professionally engaged in healthcare operations and
          must maintain medical confidentiality according to Russian law. The
          Operator transmitts only the personal data of the User that is
          necessary for the specified purpose. The Operator processes the User's
          personal data and information uploaded, transmitted and provided by
          the User through the Service according to the terms and conditions of
          the Privacy Policy available at
          https://life-air.org/en/tpost/1zi6ad6ia2-privacy-policy, insofar as it
          does not conflict with the Terms of Use. The provisions of this clause
          with respect to the processing of personal and other data of the User
          by the Operator apply to materials and information received or
          transmitted by the User in the course of using the Service and its
          functions.&nbsp;
        </li>
        <li>
          The Terms of Use may be amended by the Operator without any special
          notice. The new version of the Terms of Use comes into force on its
          posting on the Internet at the address specified in this paragraph,
          unless otherwise provided by the new version of the Terms of Use. The
          current version of the Terms of Use is available at:
          https://life-air.org/en/tpost/7gan14m0k2-terms-of-use, unless
          otherwise specified in the The Terms of Use.
        </li>
        <li>
          The Terms of Use are governed by and construed in accordance with
          Russian law. For all issues not covered by the Terms of Use, the
          parties shall comply with Russian law. &nbsp;
        </li>
        <li>
          If, for any reason, one or more of the terms and conditions of this
          The Terms of Use are found invalid or unenforceable, the validity or
          applicability of the remaining provisions of the The Terms of Use
          shall not in any way be affected.
        </li>
        <li>
          The User may at any time terminate the Terms of Use by sending a
          notice of termination to the Operator in writing. The Operator is
          entitled to terminate the Terms of Use unilaterally by notifying the
          User of termination through the Service.&nbsp;
        </li>
        <li>
          If the Operator makes any changes to the Terms of Use and the User
          does not agree to such changes, the User must stop using the Service.
        </li>
        <li>
          The Operator reserves the right to restrict the User's access to the
          Service (or to certain functions of the Service, if technologically
          possible), at their own discretion, using the User’s account or
          completely block the User's account in case of repeated violation of
          this The Terms of Use or impose other measures to the User in order to
          comply with law or the rights and legal interests of third parties.
        </li>
        <li>
          All questions, proposals and/or claims related to the use/inability to
          use the Service, as well as a potential violation of law and/or the
          rights of third parties should be sent using the feedback form at: 68
          Vavilova St, building 2, Moscow 119261.
        </li>
      </ol>
      <br />
      <br />
    </section>
  </div>
);
