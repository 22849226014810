import React from "react";
import { getIn } from "formik";

// eslint-disable-next-line react/display-name
export const FieldEnhancer = (Component) => ({
  field,
  form,
  ...componentProps
}) => {
  const isError =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  return (
    <Component
      {...field}
      {...componentProps}
      error={isError}
      onChange={(value) => {
        form.setFieldValue(field.name, value);
      }}
      onBlur={() => form.setFieldTouched(field.name)}
    />
  );
};
