/* eslint-disable no-undef */
import React from "react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

export const documentTypes = {
  PASSPORT_RUSSIA: 1,
  PASSPORT_INTER_RUSSIA: 2,
  RESIDENCE: 7,
  BIRTH_CERTIFICATE: 9,
  FOREIGN_PASSPORT: 10,
};

export const documentAttributesShow = {
  PASSPORT_RUSSIA: {
    series: true,
    num: true,
    issueDate: true,
    issuedBy: true,
    snils: true,
  },
  PASSPORT_INTER_RUSSIA: {
    series: true,
    num: true,
    issueDate: true,
    issuedBy: true,
    snils: true,
  },
  RESIDENCE: {
    series: true,
    num: true,
    issueDate: true,
    issuedBy: false
  },
  BIRTH_CERTIFICATE: {
    series: true,
    num: true,
    issueDate: true,
    issuedBy: true,
    snils: true,
  },
  FOREIGN_PASSPORT: {
    series: false,
    num: true,
    issueDate: true,
    issuedBy: false,
  },
};

export const getDocNameById = (id) => {
  return Object.keys(documentTypes).find(
    (key) =>
      documentTypes[key] === +id,
  );
};

export const Passport = (props) => {
  const { EnhancedField, EnhancedSelect, index, style } = props;
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const options = Object.keys(documentTypes).map((key) => {
    return { label: t(key), value: documentTypes[key] };
  });
  const documentAttributes = ["series", "num", "issueDate", "issuedBy", "snils"];

  const getDocName = () => {
    return Object.keys(documentTypes).find(
      (key) =>
        documentTypes[key] === +values.patients[index].documentTypeId,
    );
  };

  return (
    <>
      <div className={style.coll}>
        <Field
          component={EnhancedSelect}
          options={options}
          name={`patients.${index}.documentTypeId`}
          i18="documentType"
        />
      </div>
      {documentAttributes.map(
        (attribute) => {
          const props = {};
          if (attribute === 'issueDate') props.mask = "99.99.9999";
          return (documentAttributesShow[getDocName()] &&
            documentAttributesShow[getDocName()][attribute] && (
              <div key={`patients.${index}.${attribute}`} className={style.coll}>
                <Field
                  key={`patients.${index}.${attribute}`}
                  component={EnhancedField}
                  name={`patients.${index}.${attribute}`}
                  i18={t(attribute)}
                  {...props}
                />
              </div>)
          )
        },
      )}
    </>
  );
};
