import React from "react";

import style from "./politics.module.scss";

export const TermOfUseRU = () => (
  <div className={style.wrap}>
    <section className={style.section}>
      <h1>
        Пользовательское соглашение об использовании сервиса «Клиника Архимед»
      </h1>
      Сервис «Клиника Архимед» (далее - Сервис) создан для организации процесса
      оперативной дистанционной записи пациента (далее также - Пользователь) на
      прием в соответствующую медицинскую организацию/лабораторию с целью сдачи
      анализов (далее - услуга) и получения результата посредством предлагаемого
      в Сервисе функционала.
      <br />
      Сервис представляет собой веб-приложение для мобильных устройств и
      компьютеров, позволяющее авторизованным Пользователям заказывать услугу в
      режиме онлайн и, с помощью Сервиса, организовать распределение
      вознаграждения за оказанную услугу.
      <br />
      Перед началом использования Сервиса, пожалуйста, ознакомьтесь с условиями
      настоящего Пользовательского соглашения. Факт использования Сервиса любым
      способом (как намеренным, так и непреднамеренным) признается акцептом, то
      есть полным и безоговорочным согласием Пользователя, использовать Сервис
      на изложенных ниже условиях. Использование Сервиса допустимо исключительно
      на условиях настоящего Пользовательского соглашения. Если Пользователь не
      принимает условия Пользовательского соглашения в полном объеме, он не
      вправе использовать Сервис в каких-либо целях.
      <br />
      <br />
      <h4 className="t-redactor__h4">Общие положения</h4>
      <ol>
        <li>
          Настоящее соглашение об использовании Сервиса (далее - Соглашение)
          представляет собой публичную оферту ООО "КЛИНИКА АРХИМЕД", ИНН
          5003055720, адрес: 119261, г. Москва, ул. Вавилова, д.68, корпус 2,
          email: info@arhimedlab.ru, телефон: +7 (499) 288 07 26 (далее -
          Оператор), в соответствии с которой Оператор предоставляет
          зарегистрированным Пользователям услуги по обеспечению дистанционной
          записи на прием в медицинскую организацию/лабораторию с целью сдачи
          анализов и получения результата анализа в виде медицинской справки,
          посредством предлагаемого в Сервисе функционала, а также услуги по
          перечислению данным организациям денежных средств в качестве оплаты
          оказанных Пользователям медицинских услуг, в порядке и на условиях,
          изложенных в настоящем Соглашении.
        </li>
        <li>
          Настоящее Соглашение является юридически обязательным соглашением
          между Пользователем и Оператором, предметом которого является
          предоставление Оператором Пользователю доступа к использованию Сервиса
          и его функционала.&nbsp;
        </li>
        <li>
          Сервис предлагает Пользователям обратиться за получением платных
          медицинских услуг, а также осуществить иные действия, предусмотренные
          явными функциями Сервиса. Все существующие на данный момент функции
          Сервиса, а также любое развитие их и/или добавление новых является
          предметом настоящего Соглашения.
        </li>
        <li>
          Оператор в рамках функционирования Сервиса самостоятельно не оказывает
          никаких медицинских или консультационных услуг. Сервис обеспечивает
          доступ к авторизованным медицинским организациям, а также удобство
          коммуникации и расчетов Пользователей.
        </li>
        <li>
          Соглашение вступает в силу с момента авторизации Пользователя.
          Авторизация в Сервисе осуществляется Пользователем в соответствующем
          разделе на сайте Оператора при помощи логина (номера мобильного
          телефона или адреса электронной почты) и кода авторизации, полученного
          Пользователем от Оператора на адрес электронной почты или номер
          мобильного телефона. После авторизации Пользователь назначает в
          Сервисе пароль для своей учетной записи. Доступ к Сервису
          предоставляется по логину и паролю Пользователя. Соглашение действует
          в отношении соответствующего Пользователя до момента его расторжения в
          установленном Соглашением порядке.
        </li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">Порядок оплаты услуг</h4>
      <ol>
        <li>
          Услуги с использованием Сервиса предоставляются непосредственно
          авторизованными в Сервисе медицинскими организациями на основании
          заключенных с Пользователями договоров оказания платных медицинских
          услуг. Текст договора доводится до сведения Пользователей перед
          оказанием услуги.
        </li>
        <li>
          Услуги оказываются медицинскими организациями за плату. Цена
          определяется согласно тарифам медицинской организации, которые
          доводятся до Пользователя посредством Сервиса.
        </li>
        <li>
          Оплата услуг осуществляется Пользователем посредством Сервиса в
          порядке предоплаты на Сервис до начала оказания услуги, при этом
          Сервис является получателем платежа, действует по поручению
          Пользователя и уполномочен ими принимать от другого Пользователя
          денежные средства в счет оплаты услуг с привлечением уполномоченного
          оператора по приему платежей, или оператора электронных денежных
          средств, или иных участников расчетов, информационно-технологического
          взаимодействия. Оператор не гарантирует отсутствия ошибок и сбоев в
          отношении предоставления возможности безналичной оплаты.&nbsp;
        </li>
        <li>
          Оплату услуг Пользователь осуществляет банковской картой, указанной в
          интерфейсе Сервиса (далее – «Привязанная карта»). Оператор вправе
          списать сумму, составляющую стоимость услуг с любой Привязанной
          карты.&nbsp;
        </li>
        <li>
          Оплата услуг может быть также осуществлена Пользователем (при
          доступности Пользователю соответствующей функции) с использованием
          сервисов третьих лиц, поддерживающих интеграцию с Сервисом и
          участниками расчетов, и обеспечивающих авторизацию платежа
          Пользователя без передачи информации о Привязанной карте Оператору.
          Порядок использования сервисов третьих лиц, указанных в настоящем
          пункте, регулируется правилами таких сервисов третьих лиц. Оператор не
          несет ответственности за использование Пользователем сервисов третьих
          лиц и не гарантирует корректность и безопасность работы таких сервисов
          третьих лиц. При оплате услуг возможно проведение как одной транзакции
          на полную стоимость услуг, так и нескольких транзакций на отдельные
          части стоимости услуг.
        </li>
        <li>
          После оказания услуг, Оператор обязуется перевести на счет
          соответствующей медицинской организации, оказавшей услугу, денежные
          средства, поступившие от Пользователя в счет оплаты услуг медицинской
          организации.
        </li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">Порядок использования Сервиса</h4>Сервис
      предоставляется Пользователю для личного некоммерческого использования.
      <br />
      Сервис предоставляет Пользователю функциональную возможность доступа к
      истории действий Пользователя на Сервисе.&nbsp;
      <br />
      Пользователь обязан обеспечить конфиденциальность логина и пароля,
      необходимых для доступа к Сервису. Пользователь самостоятельно несет
      ответственность за все действия третьих лиц с использованием учетной
      записи Пользователя, при этом все такие действия считаются произведенными
      самим Пользователем.&nbsp;
      <br />В случае обнаружения несанкционированного доступа к Сервисам и/или
      нарушения (подозрениях о нарушении) конфиденциальности логина или пароля,
      Пользователь обязан незамедлительно уведомить Оператора в целях присвоения
      Пользователю нового логина и/или пароля.
      <br />
      Пользователь не вправе:&nbsp;
      <br />
      <ol>
        <li>
          Размещать в Сервисе недостоверные персональные данные, включая
          информацию о состоянии здоровья.&nbsp;
        </li>
        <li>
          Размещать и/или распространять информацию, а также ссылки на
          информацию, распространение которой запрещено действующим
          законодательством Российской Федерации.
        </li>
        <li>
          Нарушать права третьих лиц, в том числе несовершеннолетних, и/или
          причинять им вред в любой форме.
        </li>
        <li>
          Размещать и/или распространять информацию, при отсутствии необходимого
          в соответствии с законом или договором разрешения или согласия третьих
          лиц.
        </li>
        <li>
          Размещать и/или распространять не разрешенную специальным образом
          рекламную информацию, спам или вредоносные программы (ссылки на них).
        </li>
        <li>
          Собирать и хранить персональные данные других лиц без их согласия.
        </li>
        <li>Иными способами нарушать нормальную работу Сервиса.</li>
      </ol>
      Пользователь обязуется использовать Сервис только в пределах тех прав и
      теми способами, которые предусмотрены настоящим Соглашением.
      <br />
      Все графические и текстовые элементы, размещенные на сайте и мобильном
      Приложении Оператора или иным образом доступные для просмотра
      Пользователям посредством Сервиса, а также программное обеспечение в
      составе Сервиса являются объектами исключительных прав Оператора и иных
      правообладателей. Любое использование указанных объектов исключительных
      прав допускается исключительно с согласия Оператора или соответствующего
      правообладателя. В случае нарушения Пользователем требований,
      установленных настоящей статьей, Оператор вправе запретить доступ к
      Сервису посредством блокировки или удаления учетной записи Пользователя, а
      также удалить размещенную Пользователем с использованием Сервиса
      информацию.&nbsp;
      <br />
      Оператор вправе направлять Пользователям рекламные и/или информационные
      сообщения с использованием Сервиса.&nbsp;
      <br />
      <br />
      <h4 className="t-redactor__h4">Ограничение ответственности</h4>
      <ol>
        <li>Пользователь использует Сервисы на свой собственный риск.&nbsp;</li>
        <li>
          Сервис предоставляется «как есть», Оператор не несет ответственности
          за точное соответствие Сервиса требованиям Пользователя.
        </li>
        <li>
          Сервис, предоставляя функциональные возможности по обеспечению
          информационного взаимодействия между Пользователями, не является
          представителем какой-либо из сторон, в связи с чем не дает каких-либо
          гарантий относительно полноты, актуальности, корректности,
          достоверности услуг, получаемых Пользователем.&nbsp;
        </li>
        <li>
          Пользователь самостоятельно несет ответственность за любые возможные
          негативные последствия использования информации, полученной
          Пользователем с использованием Сервиса.&nbsp;
        </li>
        <li>
          Пользователь самостоятельно несет ответственность за использование
          Сервиса, включая размещение и/или распространении информации, в том
          числе, если такие действия приведут к нарушению законодательства и/или
          нарушению прав и законных интересов третьих лиц.&nbsp;
        </li>
        <li>
          Оператор не несет ответственности за убытки, понесенные Пользователем
          вследствие использования информации, полученной Пользователем
          посредством Сервиса. Оператор также не несет ответственности за
          неисполнение либо ненадлежащее исполнение настоящего Соглашения,
          явившееся результатом сбоев в телекоммуникационных и энергетических
          сетях, действия вредоносных программ, а также недобросовестных
          действий третьих лиц, выразившееся в действиях, направленных на
          несанкционированный доступ и/или выведение из строя программного и/или
          аппаратного комплекса Оператора.&nbsp;
        </li>
        <li>
          Оператор не несет ответственности за правильность введения данных
          Пользователем при использовании Сервиса и последствия таких действий
          Пользователя, за содержание, корректность и/или актуальность
          информации, предоставленной третьими лицами и доступной на Сервисе, а
          также за возможные убытки сторон.
        </li>
        <li>
          При этом, ответственность Оператора за виновные действия Оператора
          ограничена стоимостью соответствующей услуги.&nbsp;
        </li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">Электронный документооборот</h4>
      <ol>
        <li>
          Оператор и Пользователь настоящим согласовали, что в рамках Сервиса
          они взаимно признают электронные документы, подписанные простой
          электронной подписью, равнозначными документам на бумажных носителях,
          подписанным собственноручной подписью.
        </li>
        <li>
          Для подписания электронного документа электронной подписью в рамках
          Сервиса, Пользователь использует авторизацию на Сервисе. Коды, логины
          и пароли являются ключом электронной подписи.
        </li>
        <li>
          Пользователь обязан соблюдать конфиденциальность в отношении ключей
          электронной подписи.
        </li>
        <li>
          Стороны признают, что все электронные документы, сформированные,
          используемые и направляемые Оператору Пользователем посредством
          функций Сервиса в рамках одной сессии (пока пользователь является
          авторизованным на Сервисе), являются подписанными таким образом
          электронной подписью Пользователя. Подписание документов на Сервисе
          может происходить в том числе посредством проставления Пользователем в
          рамках одной сессии специального символа (галочки) напротив
          соответствующего документа (ссылки на документ).
        </li>
        <li>
          Стороны согласовали, что все действия и электронные документы,
          сделанные и направленные Оператору с использованием ключа электронной
          подписи Пользователя, считаются сделанными и направленными
          Пользователем.
        </li>
      </ol>
      <br />
      <h4 className="t-redactor__h4">Прочие условия</h4>
      <ol>
        <li>
          Пользователь дает Сервису согласие на обработку персональной
          информации Пользователя, Оператором для целей предоставления
          функциональных возможностей Сервиса, на обработку и передачу
          Оператором персональной информации Пользователя авторизованным
          медицинским организациям (равно как и на получение от них такой
          информации), оказывающим Пользователю услуги, и их уполномоченным
          представителям для дальнейшей обработки такой информации в целях
          предоставления Пользователю функциональной возможности получения от
          таких организаций услуг и в целях оказания медицинской помощи, в том
          числе в медико-профилактических целях, в целях установления
          медицинского диагноза, оказания медицинских и медико-социальных услуг
          при условии, что обработка персональных данных осуществляется лицом,
          профессионально занимающимся медицинской деятельностью и обязанным в
          соответствии с законодательством Российской Федерации сохранять
          врачебную тайну. Оператор передаёт лишь ту персональную информацию
          Пользователя, которая необходима для указанной цели. Обработка
          Оператором персональной информации Пользователя, а также иной
          информации, загружаемой, передаваемой и предоставляемой Пользователем
          посредством Сервиса, осуществляется на условиях Политики
          конфиденциальности, размещенной в сети Интернет по адресу{" "}
          <a href={`${window.location.origin}/privacypolicy`}>
            {`${window.location.origin}/privacypolicy`}
          </a>
          , в части, не противоречащей настоящему Соглашению. Положения
          настоящего пункта в отношении обработки персональной и иной информации
          Пользователя Оператором распространяются на материалы и информацию,
          полученные или переданные Пользователем в процессе использования
          Сервиса и его функций.&nbsp;
        </li>
        <li>
          Настоящее Соглашение может быть изменено Оператором без какого-либо
          специального уведомления, новая редакция Соглашения вступает в силу с
          момента ее размещения в сети Интернет по указанному в настоящем пункте
          адресу, если иное не предусмотрено новой редакцией Соглашения.
          Действующая редакция Соглашения находится на странице по адресу:{" "}
          <a href={`${window.location.origin}/termofuse`}>
            {`${window.location.origin}/termofuse`}
          </a>
          , если в Соглашении не указано иное.
        </li>
        <li>
          Настоящее Соглашение регулируется и толкуется в соответствии с
          законодательством Российской Федерации. По всем вопросам, не
          урегулированным настоящим Соглашение, стороны руководствуются
          действующим законодательством Российской Федерации.&nbsp;
        </li>
        <li>
          Если по каким-либо причинам одно или несколько условий настоящего
          Соглашения будут признаны недействительными или не имеющими
          юридической силы, это не окажет влияния на действительность или
          применимость остальных условий Соглашения.
        </li>
        <li>
          Настоящее Соглашение может быть в любой момент расторгнуто
          Пользователем посредством направления Оператору уведомления о
          расторжении в письменной форме. Оператор вправе расторгнуть Соглашение
          в одностороннем порядке, уведомив Пользователя о расторжении
          посредством Сервиса.&nbsp;
        </li>
        <li>
          В случае если Оператором были внесены какие-либо изменения в настоящее
          Соглашение, с которыми Пользователь не согласен, он обязан прекратить
          использование Сервиса.
        </li>
        <li>
          Оператор оставляет за собой право на свое усмотрение ограничить доступ
          Пользователя к Сервису (или к определенным функциям Сервиса, если это
          возможно технологически) с использованием его учетной записи или
          полностью заблокировать учетную запись Пользователя при неоднократном
          нарушении настоящего Соглашения, либо применить к Пользователю иные
          меры с целью соблюдения требований законодательства или прав и
          законных интересов третьих лиц.
        </li>
        <li>
          Все вопросы, предложения и/или претензии, связанные с
          использованием/невозможностью использования Сервиса, а также возможным
          нарушением законодательства и/или прав третьих лиц, должны
          направляться через форму обратной связи по адресу 119261, г. Москва,
          ул. Вавилова, д.68, корпус 2, email: info@arhimedlab.ru, телефон: +7
          (499) 288 07 26
        </li>
      </ol>
    </section>
  </div>
);
