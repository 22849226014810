import React from "react";
import cn from "classnames";
import { ReactSVG } from "react-svg";
import s from "./panel.module.scss";

import spoilerBtn from "./spoiler-btn.svg";

// eslint-disable-next-line react/display-name
const Panel = ({
  children,
  title,
  onClick,
  shouldBeClosed,
  withoutSpoiler,
  name,
}) => {
  const [show, toggleShow] = React.useState(true);
  React.useEffect(() => {
    if (shouldBeClosed) {
      toggleShow(false);
    }
  }, [shouldBeClosed]);
  return (
    <div className={s.panel} onClick={onClick} name={name}>
      <div className={s.panelHead}>
        <div className={s.panelTitle}>{title}</div>
        {!withoutSpoiler && (
          <ReactSVG
            className={cn(s.panelSpoiler, {
              [s["panelSpoiler--hide"]]: !show,
            })}
            onClick={() => toggleShow(!show)}
            src={spoilerBtn}
          />
        )}
      </div>
      <div className={cn(s.panelContent, { [s.panelContentShow]: show })}>
        {children}
      </div>
    </div>
  );
};

export default Panel;
