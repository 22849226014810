/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import cn from "classnames";
import { observer } from "mobx-react-lite";

import { useStores } from "../../models";
import style from "./change-language.module.scss";
import Rus from "./Rus.svg";
import Eng from "./Eng.svg";
import Zh from "./Zh.svg";
import Arrow from "./Arrow.svg";

const LANGS = [
  {
    name: "ru",
    flag: <ReactSVG src={Rus} className={style.langFlag} />,
  },
  {
    name: "en",
    flag: <ReactSVG src={Eng} className={style.langFlag} />,
  },
  {
    name: "zh",
    flag: <ReactSVG src={Zh} className={style.langFlag} />,
  },
];

const ChangeLanguage = observer(() => {
  const { userStore } = useStores();
  const { language } = userStore;
  const { i18n } = useTranslation();
  const [isOpen, changeIsOpen] = React.useState(false);
  const langRef = React.useRef();

  const handleClick = React.useCallback(
    ({ target }) => {
      if (!langRef.current) return;
      if (!langRef.current.contains(target)) {
        changeIsOpen(false);
      }
  },
    [isOpen],
  );

  let currentLang;

  if (language) {
    currentLang = LANGS.find((lang) => lang.name === language);
  } else {
    currentLang = LANGS[0];
  }

  const langList = LANGS.map((lang) => {
    if (lang.name !== currentLang.name) {
      return (
        <li key={lang.name} onClick={() => changeLang(lang.name)}>
          {lang.flag}
          <div className={style.language}>{lang.name}</div>
        </li>
      );
    }
    return null;
  });

  React.useEffect(() => {
    userStore.setLanguage(language);
  }, [language, userStore]);

  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [isOpen]);

  const changeLang = (langName) => {
    i18n.changeLanguage(langName).then(() => {
      changeIsOpen(false);
      userStore.setLanguage(langName);
    });
  };

  return (
    <div
      className={style.changeLanguage}
      onClick={() => changeIsOpen(!isOpen)}
      ref={langRef}
    >
      {currentLang.flag}
      <div className={style.language}>{currentLang.name}</div>
      <ReactSVG src={Arrow} className={isOpen ? style.rotate : ""} />

      {isOpen && (
        <ul className={cn(style.langPicker, style.changeLanguage)}>
          {langList}
        </ul>
      )}
    </div>
  );
});

export default ChangeLanguage;
