import React from "react";

import { PrivacyPolicyRU } from "./PrivacyPolicyRU";
import { PrivacyPolicyENG } from "./PrivacyPolicyENG";

import i18n from "../../i18n/i18n";

export const PrivacyPolicy = () => {
  const [curLanguage, changeCurLanguage] = React.useState(i18n.language);
  React.useEffect(() => {
    changeCurLanguage(i18n.language);
  }, []);
  if (curLanguage === "ru") {
    return <PrivacyPolicyRU />;
  } else {
    return <PrivacyPolicyENG />;
  }
};
