import React from "react";
import { MainForm } from "../../components";
import style from "./order.module.scss";

export const Order = ({ ...props }) => {
  return (
    <div className={style.main}>
      <MainForm {...props} />
    </div>
  );
};
