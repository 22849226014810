import { types, flow, getRoot } from "mobx-state-tree";
import { OrderModel } from "./OrderModel";
import { Api } from "../api/api";

export const OrderStore = types
  .model("OrderStore", {
    orders: types.optional(types.array(OrderModel), []),
    pending: types.optional(types.boolean, true),
    error: types.optional(types.string, ""),
    currentOrder: types.maybeNull(types.safeReference(OrderModel, null)),
  })
  .actions((self) => ({
    afterCreate() {
      self.currentOrder = null;
      self.error = "";
    },
    setCurrentOrder(id) {
      self.currentOrder = id;
    },
    paid(id) {
      const d = new Date();
      self.orders = self.orders.map((order) =>
        order.id === id ? { ...order, paid: d.toISOString() } : order,
      );
    },
    add: flow(function* (order) {
      self.currentOrder = null;
      self.pending = true;
      const result = yield new Api().createOrder(order);
      if (result.kind === "ok") {
        getRoot(self).patientStore.addOrderPatients({ ...result.order });
        self.orders.push(OrderModel.create({ ...result.order }));
        self.setCurrentOrder(result.order.id);
        self.pending = false;
      } else {
        getRoot(self).toastStore.addError(result.kind);
        self.pending = false;
      }
    }),
    getOrders: flow(function* getOrders() {
      self.error = "";
      self.pending = true;
      const rememberCurrentOrder = self.currentOrder;
      const result = yield new Api().getOrders();
      if (result.kind === "ok") {
        self.orders = [];
        for (let order of result.orders) {
          getRoot(self).patientStore.addOrderPatients({ ...order });
          self.orders.push(OrderModel.create({ ...order }));
        }
        if (rememberCurrentOrder) self.setCurrentOrder(rememberCurrentOrder.id);
      } else {
        self.error = result.kind;
      }
      self.pending = false;
    }),
    getOrderById: flow(function* getOrderById(id) {
      // const currentOrderId = self.currentOrder?.id;
      self.error = "";
      let order = self.orders.find((order) => order.id === id);
      if (order && order.patients.length > 0) {
        // self.currentOrder = order.id;
      } else {
        self.pending = true;
        const result = yield new Api().getOrderById(id);
        if (result.kind === "ok") {
          const resultOrder = OrderModel.create({
            ...result.order,
          });
          const isPresent = self.orders.find((o) => o.id === id);
          if (isPresent)
            self.orders = self.orders.map((order) =>
              order.id === id ? resultOrder : order,
            );
          if (!isPresent) self.orders.push(resultOrder);
          getRoot(self).patientStore.addOrderPatients({
            ...result.order,
          });
          self.setCurrentOrder(id);
        } else {
          getRoot(self).toastStore.addError(result.kind);
        }
        self.pending = false;
      }
    }),
  }));
