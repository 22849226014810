import { types, flow, getRoot } from "mobx-state-tree";
import { ReferralModel } from "./ReferralModel";

import { Api } from "../api/api";

export const ReferralStore = types
  .model("ReferralStore")
  .props({
    referrals: types.optional(types.array(ReferralModel), []),
    pending: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    afterCreate() {
      self.referrals = []
    },
    getRefferals: flow(function* getRefferals() {
      self.pending = true;
      const result = yield new Api().getRefferals();
      if (result.kind === "ok") {
        self.referrals = [];
        self.referrals = result.referrals.map((referral) =>
          ReferralModel.create(referral),
        );
        result.referrals.map((referral) => {
          getRoot(self).patientStore.add({ ...referral.patientRaw, id: referral.patientRaw.id.toString() });
        });
        self.pending = false;
      } else {
        self.pending = false;
        getRoot(self).toastStore.addError(result.kind);
      }
    }),
    getReferralById: flow(function* getReferralById(id) {
      self.pending = true;
      const result = yield new Api().getReferralById(id);
      if (result.kind === "ok") {
        self.referrals = result.referrals.map((referral) =>
          ReferralModel.create(referral),
        );
        self.pending = false;
      } else {
        self.pending = false;
        getRoot(self).toastStore.addError(result.kind);
      }
    }),
    add(values) {
      const referral = ReferralModel.create({...values, id: values.id.toString()});
      self.referrals.push(referral);
    },
    addPatientReferral(patient) {
      self.add({ patient: patient.id.toString(), ...patient.referral });
    },
    addReferrallsForOrder(referrals) {
      referrals.forEach((referral) => self.add(referral));
    },
    getReferralsByOrderId: flow(function* getReferralsByOrderId(orderId) {
      self.pending = true;
      const result = yield new Api().getReferralsByOrderId(orderId);
      if (result.kind === "ok") {
        self.addReferrallsForOrder(result.referrals)
        // result.referrals.forEach((referral) => self.add(referral));
      } else {
        getRoot(self).toastStore.addError(result.kind);
      }
    }),
  }));
