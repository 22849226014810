import React from "react";
import style from "../Politics/politics.module.scss";
import icon from "./icon_registry.png";

export const LicenseRegistry = ({ changeModal }) => {
  return (
    <div className={style["wrap"]}>
      <section className={style["section"]}>
        <h2>Образовательная Лицензия</h2>

        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ height: 220 }}>
              <img src={icon} width={150} alt="Educational license" />
            </div>
            <a
              style={{ fontSize: 14 }}
              href="./edu_license.pdf"
            >
              Скачать лицензию
            </a>
          </div>
        </div>
        <div style={{ height: 40 }} />
        <p>
          Лицензия №ЛО35-01298-77/01316187 от 25.07.2024 на право осуществления
          образовательной деятельности по реализации образовательных программ по
          видам образования, уровням образования, по профессиям, специальностям,
          направлениям подготовки (для профессионального образования), по
          подвиду дополнительного образования: дополнительное образование.
          Лицензия выдана Департаментом образования и науки города Москвы.
          Информация о лицензии, её сроке действия и текущем статусе размещена
          на официальном сайте Федеральной службы по надзору в сфере
          здравоохранения (<a href="https://roszdravnadzor.gov.ru/services/licenses">https://roszdravnadzor.gov.ru/services/licenses</a>)
          (поиск по номеру ИНН 5003055720)
        </p>
      </section>
    </div>
  );
};
