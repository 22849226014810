import React, { useEffect } from "react";
import styled from "styled-components";
import ClickAwayListener from "react-click-away-listener";
import arrowDown from "./arrow_down.svg";
import { useTranslation } from "react-i18next";
import { isEmptyArray } from "formik";

export const MultiSelect = ({
  error,
  options,
  placeholder,
  noOptionsMessage,
  i18,
  value,
  disabled,
  onChange,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [countSelected, setCountSelected] = React.useState(0);

  const handleClickAway = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (options && selectedOptions) {
      const optionsIds = options.map((item) => item.value);
      const selectedOptionsIds = Object.keys(selectedOptions);
      // check if optionsIds include selectedOptionsIds
      const isOptionsIdsIncludesSelectedOptionsIds = optionsIds.every((item) =>
        selectedOptionsIds.includes(item),
      );
      if (!isOptionsIdsIncludesSelectedOptionsIds) {
        setSelectedOptions({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    const selected = {};
    if (!isEmptyArray(value.filter(Boolean))) {
      value.filter(Boolean).forEach((item) => {
        selected[item] = true;
      });
      setSelectedOptions(selected);
      onChange(Object.keys(selected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cleanValues = value.filter(Boolean);
    const selected = {};
    cleanValues.forEach((item) => {
      selected[item] = true;
    });
    setSelectedOptions(selected);
  }, [value]);

  const toggling = () => setIsOpen((state) => !state);

  const onOptionClicked = (value) => () => {
    if (disabled) return;
    const newState = { ...selectedOptions };
    newState[value] = !newState[value];
    setSelectedOptions(newState);
    const selected = Object.keys(newState).filter(
      (key) => newState[key] === true,
    );
    onChange(selected);
  };

  React.useEffect(() => {
    setCountSelected(Object.values(selectedOptions).filter(Boolean).length);
    setIsOpen(false);
  }, [selectedOptions]);

  const currentPlaceholder =
    countSelected === 0 ? placeholder : `Выбрано: ${countSelected}`;

  const isSelected = (value) => {
    return selectedOptions[value];
  };

  if (!options || options.length === 0) {
    return null;
  }

  return (
    <Label error={Boolean(error)}>
      {t(i18)}
      <ClickAwayListener onClickAway={handleClickAway}>
        <DropDownContainer
          onClick={toggling}
          error={Boolean(error)}
          isOpen={isOpen}
        >
          <DropDownHeader>{currentPlaceholder}</DropDownHeader>
          {isOpen && options.length === 0 && (
            <DropDownList>
              <NoOptionsMessage>{noOptionsMessage()}</NoOptionsMessage>
            </DropDownList>
          )}
          {isOpen && options.length > 0 && (
            <DropDownListContainer>
              <DropDownList>
                {options.map((option) => (
                  <div key={option.value}>
                    <ListItem
                      isSelected={isSelected(option.value)}
                      onClick={onOptionClicked(option.value)}
                    >
                      {option?.label}
                    </ListItem>
                  </div>
                ))}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      </ClickAwayListener>
      <Tags>
        {Object.entries(selectedOptions)
          .filter((v) => Boolean(v[1]))
          .map(([key]) => {
            return (
              <Tag key={key}>
                <TitleTag>
                  {
                    options.find(
                      (option) => Number(option.value) === Number(key),
                    )?.label
                  }
                </TitleTag>
                {!disabled && (
                  <CloseTag onClick={onOptionClicked(key)}>x</CloseTag>
                )}
              </Tag>
            );
          })}
      </Tags>
      {error && <Error>{error}</Error>}
    </Label>
  );
};

const Error = styled("span")`
  display: block;
  color: red;
  font-size: 12px;
`;

const Label = styled("label")`
  font-size: 14px;
  color: ${(props) => (props.error ? "red" : "#767676")};
`;

const DropDownContainer = styled("div")`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #767676;
  border-color: ${(props) => props.error ?? "red"};
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  background-color: white;
  position: relative;
  cursor: pointer;
  &--disabled {
    background-color: #ececec;
  }
  &:after {
    position: absolute;
    content: "";
    top: 18px;
    right: 20px;
    width: 14px;
    height: 14px;
    background-image: url(${arrowDown});
    background-size: 14px 14px;
    transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
    border-color: #fff transparent transparent transparent;
  }
`;

const DropDownHeader = styled("div")`
  /* margin-bottom: 0.8em; */
`;

const DropDownListContainer = styled("div")`
  z-index: 999;
`;

const DropDownList = styled("ul")`
  position: absolute;
  padding: 0;
  margin-top: 10px;
  margin-left: -15px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  z-index: 1000;
  width: 100%;
  background-color: white;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  z-index: 1;
  margin-bottom: 0.8em;
  padding-left: 20px;
  color: ${(props) => props.isSelected && "white"};
  background-color: ${(props) => props.isSelected && "#AFCC54"};
  margin-bottom: 0;
  font-size: 16px;
  &:hover {
    background-color: #4fb200;
    color: white;
  }
`;

const Tags = styled("div")`
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  margin-top: 10px;
  gap: 10px;
`;

const Tag = styled("div")`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  /* margin-bottom: 10px; */
  color: white;
  flex: 1 auto;
  flex-flow: row nowrap;
  align-items: space-between;
  justify-content: space-between;
  background-color: #afcc54;
`;

const CloseTag = styled("span")`
  cursor: pointer;
`;

const TitleTag = styled("div")``;

const NoOptionsMessage = styled("div")`
  font-size: 15px;
  padding: 20px;
  line-height: 20px;
`;
