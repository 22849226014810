import React from "react";
import { useTranslation } from "react-i18next";

import styleHome from "../../home.module.scss";
import style from "./servicesSection.module.scss";

export function ServicesSection() {
  const { t } = useTranslation();

  return (
    <div className={`${styleHome["st-section"]} ${style["services-section"]}`}>
      <div className="container">
        <ul className={style["services"]}>
          <li>
            <div className={style["services__icon"]}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                {" "}
                <path
                  d="M9 40V8C9 5.8 10.8 4 13 4H29C31.2 4 33 5.8 33 8V40C33 42.2 31.2 44 29 44H13C10.8 44 9 42.2 9 40Z"
                  fill="#37474F"
                ></path>{" "}
                <path
                  d="M29 7H13C12.4 7 12 7.4 12 8V37C12 37.6 12.4 38 13 38H29C29.6 38 30 37.6 30 37V8C30 7.4 29.6 7 29 7Z"
                  fill="#F8F7FF"
                ></path>{" "}
                <path d="M18 40H24V42H18V40Z" fill="#78909C"></path>{" "}
                <path
                  d="M42 11H24C22.895 11 22 11.895 22 13V31L28 27H42C43.105 27 44 26.105 44 25V13C44 11.895 43.105 11 42 11Z"
                  fill="#AFCC54"
                ></path>{" "}
                <path
                  d="M33 14C32.7348 14 32.4804 14.1054 32.2929 14.2929C32.1054 14.4804 32 14.7348 32 15C32 15.2652 32.1054 15.5196 32.2929 15.7071C32.4804 15.8946 32.7348 16 33 16C33.2652 16 33.5196 15.8946 33.7071 15.7071C33.8946 15.5196 34 15.2652 34 15C34 14.7348 33.8946 14.4804 33.7071 14.2929C33.5196 14.1054 33.2652 14 33 14ZM33 22C32.7348 22 32.4804 22.1054 32.2929 22.2929C32.1054 22.4804 32 22.7348 32 23C32 23.2652 32.1054 23.5196 32.2929 23.7071C32.4804 23.8946 32.7348 24 33 24C33.2652 24 33.5196 23.8946 33.7071 23.7071C33.8946 23.5196 34 23.2652 34 23C34 22.7348 33.8946 22.4804 33.7071 22.2929C33.5196 22.1054 33.2652 22 33 22Z"
                  fill="white"
                ></path>{" "}
                <path
                  d="M36 21V18C36 16.343 34.657 15 33 15C31.343 15 30 16.343 30 18V21C30 21.552 29.552 22 29 22V23H37V22C36.448 22 36 21.552 36 21Z"
                  fill="white"
                ></path>{" "}
              </svg>
            </div>
            <div className={style["services__title"]}>
              {t("landing.services.service_1_title")}
            </div>
            <div className={style["services__about"]}>
              {t("landing.services.service_1_text")}
            </div>
          </li>

          <li>
            <div className={style["services__icon"]}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                {" "}
                <path
                  d="M36 4C36 13.3 30 17.2 23.2 21.8C16.1 26.5 8 31.8 8 44H12C12 33.9 18.5 29.6 25.4 25.1C32.2 20.6 40 15.4 40 4H36Z"
                  fill="#3E3E5A"
                ></path>{" "}
                <path
                  d="M38 41H11C10.4 41 10 40.48 10 39.7C10 38.92 10.4 38.4 11 38.4H38C38.6 38.4 39 38.92 39 39.7C39 40.48 38.6 41 38 41ZM36 35.8H12C11.4 35.8 11 35.28 11 34.5C11 33.72 11.4 33.2 12 33.2H36C36.6 33.2 37 33.72 37 34.5C37 35.28 36.6 35.8 36 35.8ZM31 30.6H17C16.4 30.6 16 30.08 16 29.3C16 28.52 16.4 28 17 28H31C31.6 28 32 28.52 32 29.3C32 30.08 31.6 30.6 31 30.6Z"
                  fill="#3E3E5A"
                ></path>{" "}
                <path
                  d="M37 9.6H10C9.4 9.6 9 9.08 9 8.3C9 7.52 9.4 7 10 7H37C37.6 7 38 7.52 38 8.3C38 9.08 37.6 9.6 37 9.6ZM36 14.8H12C11.4 14.8 11 14.28 11 13.5C11 12.72 11.4 12.2 12 12.2H36C36.6 12.2 37 12.72 37 13.5C37 14.28 36.6 14.8 36 14.8ZM31 20H18C17.4 20 17 19.48 17 18.7C17 17.92 17.4 17.4 18 17.4H31C31.6 17.4 32 17.92 32 18.7C32 19.48 31.6 20 31 20Z"
                  fill="#AFCC54"
                ></path>{" "}
                <path
                  d="M36 4C36 13.3 30 17.2 23.2 21.8L25.4 25.1C32.2 20.6 40 15.4 40 4H36Z"
                  fill="#3E3E5A"
                ></path>{" "}
                <path
                  d="M40 44H36C36 33.9 29.5 29.6 22.6 25.1C15.8 20.6 8 15.4 8 4H12C12 13.3 18 17.2 24.8 21.8C31.9 26.5 40 31.8 40 44Z"
                  fill="#AFCC54"
                ></path>{" "}
              </svg>
            </div>
            <div className={style["services__title"]}>
              {t("landing.services.service_2_title")}
            </div>
            <div className={style["services__about"]}>
              {t("landing.services.service_2_text")}
            </div>
          </li>

          <li>
            <div className={style["services__icon"]}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                {" "}
                <path
                  d="M39 44H9V4H29.625L39 13.5238V44Z"
                  fill="#41445F"
                ></path>{" "}
                <path d="M38 15H29V6L38 15Z" fill="#9DA0C1"></path>{" "}
                <path
                  d="M46 38C46 42.4184 42.4176 46 38 46C33.5816 46 30 42.4184 30 38C30 33.5816 33.5816 30 38 30C42.4176 30 46 33.5816 46 38Z"
                  fill="#AFCC54"
                ></path>{" "}
                <path d="M36.4 33.2H39.6V42.8H36.4V33.2Z" fill="white"></path>{" "}
                <path
                  d="M33.2 36.3999H42.8V39.5999H33.2V36.3999Z"
                  fill="white"
                ></path>{" "}
              </svg>
            </div>
            <div className={style["services__title"]}>
              {t("landing.services.service_3_title")}
            </div>
            <div className={style["services__about"]}>
              {t("landing.services.service_3_text")}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
