import React from "react";
import AsyncSelect from "react-select";

import Field from "../Field/Field";

export const Select = ({ ...props }) => {
  const colourStyles = {
    control: () => ({
      border: "none",
      "background-color": "transparent",
      display: "flex",
      "justify-content": "space-between",
      height: "50px",
      "hover:": {
        border: "none",
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: 0,
    }),
  };
  if (!props.options) console.log('noOptions for select', props)
  const value = props.options?.find((item) => item.value === props.value)
  const Component = (
    <AsyncSelect
      value={value}
      styles={colourStyles}
      // isSearchable={false}
      cacheOptions
      options={props.options}
      disabled={props.disabled}
      onChange={({ value }) => props.onChange(value)}
      onBlur={props.onBlur}
      touched={props.touched}
      noOptionsMessage={props.noOptionsMessage}
      isMulti={props.isMulti}
    />
  );
  return <Field Component={Component} {...props} value={value} />;
};
