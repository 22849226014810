import { types, flow, getRoot } from "mobx-state-tree";
import LogRocket from "logrocket";
import { socket } from "../context/socket"
// import * as storage from "./storage";
import { UserModel } from "./UserModel";
import { Api } from "../api/api";
import { getLanguage } from "../i18n/i18n.js";

export const UserStore = types
  .model("UserStore", {
    user: types.optional(UserModel, {}),
    pending: types.optional(types.boolean, false),
    error: types.optional(types.string, ""),
    sucess: types.optional(types.string, ""),
    language: types.maybe(
      types.enumeration("Language", ["ru", "en", "zh"]),
      "",
    ),
  })
  .views((self) => ({
    get authenticated() {
      return self.user.jwt ? true : false;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.checkMe();
      self.setLanguage(localStorage.getItem("lang") || getLanguage());
      LogRocket.identify(self.user?.username);
    },
    setLanguage(language) {
      self.language = language;
      localStorage.setItem("lang", self.language);
    },
    // checkMe: flow(function* me() {
    checkMe() {
      // const result = yield new Api().getMe();
      LogRocket.identify(self.user?.username);
      // if (result?.data?.ok) {
      //   getRoot(self).toastStore.addsuccess(self.user.username);
      // } else {
      //   // self.clearUser();
      // }
    },

    sendCode: flow(function* sendCode(username, email) {
      self.error = "";
      self.pending = true;
      const result = yield new Api().sendCode(username, email);
      self.pending = false;
      if (result && result.kind === "ok") {
        getRoot(self).toastStore.addSuccess(
          "Звонок с кодом заказан / Code sent by call",
        );
      } else {
        getRoot(self).toastStore.addError(result.data?.problem);
      }
    }),
    checkCode: flow(function* checkCode(username, smsCode, email) {
      self.error = "";
      const result = yield new Api().checkCode({ username, smsCode, email });
      self.pending = false;
      if (result && result.kind === "ok") {
        self.user = {
          jwt: result.data.jwt,
          username: result.data.username,
        };
        socket.auth = { username: username };
        LogRocket.identify(result.data.username);
      } else {
        self.error = "invalid_sms_code";
      }
    }),
    clearUser: () => {
      self.user = {
        jwt: "",
        username: "",
        error: "",
        pending: false,
      };
      // storage.clear();
    },
    getUser: () => {
      return self.user.username;
    },
  }));
