import React, { useCallback, useEffect } from "react";
import { Formik, Form } from "formik";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Persist } from "formik-persist";
import { Redirect, useParams } from "react-router-dom";

import { FieldEnhancer } from "../../hoc/FieldEnhancer";
import {
  PatientForm,
  ApproveNumberForm,
  ChooseTestPlace,
  PayChoose,
  Input,
  Select,
  Attention,
  MultiSelect,
} from "..";
import Panel from "../Panel/Panel";
import { PAY_METHODS } from "../Switcher/Switcher";
import style from "./main-form.module.scss";
import { useStores } from "../../models";
import { validattionCnfg } from "./validattionCnfg";
import { ScrollToError } from "./ScrollToError";

export const MainForm = observer(({ ...props }) => {
  const { id, offline, placeId, productId } = useParams();
  const [redirect, setRedirect] = React.useState(null);
  const { userStore, toastStore, orderStore, placeStore, productStore } = useStores();
  const { places, pending: placePending } = placeStore;
  const { language } = userStore;
  const { currentOrder } = orderStore;
  const { error, user } = userStore;

  const isPending = placePending || orderStore.pending

  const formikRef = React.useRef();
  const numberRef = React.createRef();

  const initialValuesPatient = {
    lastName: "",
    firstName: "",
    middleName: "",
    firstNameLastNameEng: "",
    dateOfBirth: "",
    passport: "",
    passportNumber: "",
    passportAuthority: "",
    address: "",
    snils: "",
    workOrStudyPlace: "",
    flightNumber: "",
    sex: "",
    product: [productId] || [],
  };

  const initialValues = {
    place: placeId || null,
    phoneNumberApproved: false,
    patients: [initialValuesPatient],
    paymentMethod: PAY_METHODS.online,
  };

  React.useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(
        "phoneNumberApproved",
        userStore.getUser() ? true : false,
      );
    }
  }, [user.username, userStore]);

  React.useEffect(() => {
    formikRef.current.setFieldValue("place", id);
  }, [id]);

  React.useEffect(() => {
    if (placeId && productId) formikRef.current.setFieldValue("place", placeId);
  }, [placeId, productId]);

  React.useEffect(() => {
    if (currentOrder) setRedirect(`/order/${currentOrder.id}`);
    return () => setRedirect(null);
  }, [currentOrder]);

  React.useEffect(() => {
    orderStore.setCurrentOrder(null);
  }, [orderStore]);

  const EnhancedField = FieldEnhancer(Input);
  const EnhancedSelect = FieldEnhancer(Select);
  const EnhancedMultiSelect = FieldEnhancer(MultiSelect);

  const [paymentTouched, setPaymentTouched] = React.useState(false);

  const onSubmit = async (values, { resetForm }) => {
    const body = {
      patients: values.patients,
      paymentMethod: values.paymentMethod,
      place: values.place,
      code: values.code,
    };
    await orderStore.add(body);
    if (currentOrder) {
      resetForm();
    }
  };

  const { t } = useTranslation();

  const getCn = (isOk) => cn(style.step, { [style["step--current"]]: isOk });

  React.useEffect(() => {
    if (error) {
      if (!error) return;
      toastStore.addError(t(error));
    }
  }, [error, t, toastStore]);

  const getPlaceName = (id) => {
    const place = places.find((item) => item.id === id);
    return language === "ru" ? place?.name : place?.nameEn;
  };

  return (
    <Formik
      validationSchema={validattionCnfg(t)}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange
      validateOnBlur
      enableReinitialize
      innerRef={(r) => (formikRef.current = r)}
    >
      {({
        handleSubmit,
        values,
        errors,
        isValid,
        setFieldValue,
        isSubmitting,
        touched,
        ...r
      }) => {
        const productsForPlace = useCallback(() => {
          if (!values.place) return;
          return values.place
            ? places.find((p) => p.id === values.place?.toString())?.products
            : [];
        }, [values.place]);

        const onlyOnlinePayment = useCallback(() => {
          return values.place
            ? placeStore.places.find((p) => p.id === values.place?.toString())
                ?.onlyOnlinePayment
            : false;
        }, [values.place]);

        useEffect(() => {
          values.patients.forEach((patient, index) => {
            const products = patient?.product?.map((productId) =>
              productStore?.products?.find((p) => p.id === productId),
            );
            const productCodes = products?.map((product) => product?.productCode).filter(Boolean);
            const productCodesUniq = productCodes.filter((p, i) => productCodes.indexOf(p) === i);
            if (productCodesUniq.length !== productCodes.length) { 
              toastStore.addError((t("form_validation_same_product_code")));
              setFieldValue(`patients.${index}.product`, [...patient?.product].slice(0, -1));
            }
          });
        }, [setFieldValue, values.patients]);

        const renderSteps = useCallback(() => {
          return (
            <div className={style.steps}>
              {
                <div className={getCn(values.place && !errors.testPlaceId)}>
                  {t("itemSelection")}{" "}
                  {values.place && getPlaceName(values.place)}
                </div>
              }
              <div
                className={getCn(
                  values.phoneNumberApproved && !errors.phoneNumberApproved,
                )}
              >
                {t("numberConfirmation")}
              </div>
              <div className={getCn(!errors.patients)}>{t("patientData")}</div>
              <div className={getCn(paymentTouched)}>{t("payment")}</div>
            </div>
          );
        }, [
          errors.patients,
          errors.phoneNumberApproved,
          errors.testPlaceId,
          values.phoneNumberApproved,
          values.place,
        ]);

        return (
          <main className={style.main}>
            {isSubmitting && <ScrollToError errors={errors} />}
            <div className={style.head}>
              <div className="container">
                <div className={style.headInner}>
                  <h1>{t("bookingCovidTest")}</h1>
                  {renderSteps()}
                </div>
              </div>
            </div>
            <div className="container">
              <Form className={style.form} onSubmit={handleSubmit}>
                <Persist name="order-form" />
                {placeId && (
                  <Panel
                    withoutSpoiler
                    title={
                      values.place && !isPending
                        ? `${t("testPlaceChoosed")}: ${getPlaceName(
                            values.place,
                          )}`
                        : t("testPlaceTitle")
                    }
                    shouldBeClosed={values.place}
                    name="place"
                  ></Panel>
                )}
                {!placeId && (
                  <Panel
                    title={
                      values.place && !isPending
                        ? `${t("testPlaceChoosed")}: ${getPlaceName(
                            values.place,
                          )}`
                        : t("testPlaceTitle")
                    }
                    shouldBeClosed={values.place}
                    name="place"
                  >
                    <ChooseTestPlace numberRef={numberRef} />
                  </Panel>
                )}
                <div ref={numberRef}></div>
                <Panel
                  title={t("numberConfirmationTitle")}
                  name="phoneNumberApproved"
                >
                  <ApproveNumberForm
                    {...props}
                    EnhancedField={EnhancedField}
                    onChange={setFieldValue}
                    phoneNumberApproved={values.phoneNumberApproved}
                  />
                </Panel>
                <Panel title={t("patientDataTitle")} name="patients">
                  <PatientForm
                    patients={values.patients}
                    products={productsForPlace()}
                    touched={touched}
                    initialValuesPatient={initialValuesPatient}
                    EnhancedField={EnhancedField}
                    EnhancedSelect={EnhancedSelect}
                    EnhancedMultiSelect={EnhancedMultiSelect}
                  />
                </Panel>
                <div className="container">
                  <div className={style.headInner}>{renderSteps()}</div>
                </div>
                <Panel
                  title={t("paymentMethod")}
                  onClick={() => setPaymentTouched(true)}
                  name="paymentMethod"
                >
                  <PayChoose
                    isPending={isPending}
                    offline={offline}
                    EnhancedField={EnhancedField}
                    patients={values.patients}
                    onNextStep={handleSubmit}
                    isValid={isValid && r.dirty}
                    onlyOnlinePayment={onlyOnlinePayment()}
                    products={productsForPlace()}
                  />
                </Panel>
                <Attention />
                {(!process.env.NODE_ENV ||
                  process.env.NODE_ENV === "development") && (
                  <pre>
                    <code>{JSON.stringify(values, null, 2)}</code>
                  </pre>
                )}
              </Form>
              {redirect && <Redirect to={redirect} />}
            </div>
          </main>
        );
      }}
    </Formik>
  );
});
