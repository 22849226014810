import React from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

// import { Button } from "../../components";
import { withTranslation } from "react-i18next";
import {
  Table as RTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "react-super-responsive-table";
import "./responsible.css";

import style from "./orders.module.scss";

const getpaymentMethod = (paymentMethod, t) => (
  <div className={style[`tableItem--${paymentMethod}`]}>{t(paymentMethod)}</div>
);
const getPaid = (isPaid, t) => (
  <div className={style[`tableItem--${isPaid}`]}>{t(isPaid)}</div>
);

const Table = observer(({ orders = [], t }) => (
  <div className={cn(style.containerTable)}>
    <RTable className={style.table}>
      <Thead>
        <Tr className={style.topBorder}>
          <Th className={cn(style.title, style.pdLeft)}>{t("order_number")}</Th>
          <Th className={style.title}>{t("direction_number")}</Th>
          <Th className={style.title}>{t("price")}</Th>
          <Th className={style.title}>{t("pay_method")}</Th>
          <Th className={style.title}>{t("is_paid")}</Th>
          <Th className={style.title}>{t("pay")}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {orders.map((order) => (
          <Tr className={style.trWrapper} key={order.id}>
            <Td className={cn(style.tableItem, style.pdLeft)}>{order.id}</Td>
            <Td className={style.tableItem}>
              {console.log(order.patients, null, 2)}
              {order.patients.map((p) => (
                <p key={"ref" + p.id}>{p.referral?.referralId}</p>
              ))}
            </Td>
            <Td className={style.tableItem}>{`${order.sum} \u20bd`}</Td>
            <Td className={cn(style.tableItem)}>
              {getpaymentMethod(order.paymentMethod, t)}
            </Td>
            <Td className={style.tableItem}>
              {getPaid(order.paid ? "paid" : "unpaid", t)}
            </Td>
            <Td className={style.tableItem}>
              {order.paymentMethod === "online" && !order.paid && (
                <Link
                  to={`/order/${order.id}/`}
                  className={style[`tableItem--pay`]}
                >
                  {t("pay")}
                </Link>
              )}
            </Td>
            <Td className={style.tableItem}>
              <Link to={`/order/${order.id}/`}>{t("go_to_order")}</Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </RTable>
  </div>
));

export default withTranslation()(Table);
