import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react-lite";
import { useStores } from "../models";

import { DefaultLayout } from "../screens/layouts";

export const RouteWrapper = observer(
  ({ component: Component, title, isPrivate, ...rest }) => {
    let { userStore } = useStores();
    let { authenticated } = userStore;

    if (isPrivate && !authenticated) {
      return <Redirect to="/" />;
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          <div>
            <Helmet>
              <title>{title}</title>
              <meta name="description" content={`${title}`} />
            </Helmet>
            <DefaultLayout>
              <Component {...props} />
            </DefaultLayout>
          </div>
        )}
      />
    );
  },
);
