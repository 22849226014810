import { types } from "mobx-state-tree";
import { ProductModel } from "./ProductModel";

export const PlaceModel = types.model("Order", {
  id: types.identifier,
  name: types.string,
  nameEn: types.string,
  onlyTerminal: types.boolean,
  infoRus: types.maybe(types.string, ""),
  infoEng: types.maybe(types.string, ""),
  infoFullRus: types.maybe(types.string, ""),
  infoFullEng: types.maybe(types.string, ""),
  products: types.array(types.reference(ProductModel)),
  show: types.boolean,
  onlyOnlinePayment: types.maybeNull(types.boolean),
  cloudPayments: types.maybeNull(types.boolean),
  cloudPublicId: types.maybeNull(types.string),
  robokassa: types.maybeNull(types.boolean),
});
// .actions(() => {})
// .views(() => {});
