import { types } from "mobx-state-tree";

export const ProductModel = types
  .model("Order", {
    id: types.identifier,
    name: types.string,
    nameEn: types.string,
    price: types.number,
    nameForCheck: types.string,
    samplingCode: types.maybeNull(types.string),
    samplingPrice: 250,
    productCode: types.maybeNull(types.string),
    // place: types.array(types.reference(types.late(() => PlaceModel))),
  })
