import React, { useState } from "react";
import style from "./question.module.scss";

export function Question(props) {
  const [isActive, setActive] = useState(false);

  const handleToggle = () => setActive(!isActive);

  return (
    <li className={isActive ? style["question_open"] : ""}>
      <div className={style["questions__head"]} onClick={handleToggle}>
        {props.question.title}
      </div>
      <div className={style["questions__body-answer"]}>
        {props.question.answer}
      </div>
    </li>
  );
}
