import React from "react";
import style from "./card.module.scss";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import marked from "marked";
import { useStores } from "../../models";
import { useTranslation } from "react-i18next";

export const Card = observer(
  ({ place, children, choosed, className, showInfoFull }) => {
    const { t } = useTranslation();
    const { userStore } = useStores();
    const { language } = userStore;
    const placeName = language === "ru" ? place?.name : place?.nameEn;
    const placeInfo = language === "ru" ? place?.infoRus : place?.infoEng;
    const placeInfoFull =
      language === "ru" ? place?.infoFullRus : place?.infoFullEng;
    // const splitedInfoFull = placeInfoFull && placeInfoFull.split(".");
    return (
      <div
        className={
          className
            ? className
            : cn(style.card, { [style["card--choosed"]]: choosed })
        }
      >
        <div className={style.place}>{placeName}</div>
        {place?.onlyTerminal && (
          <div className={style.subTextPlace}>
            {t("only_terminal_passengers")}
          </div>
        )}
        <div
          className={style.time}
          dangerouslySetInnerHTML={{ __html: marked(placeInfo || "") }}
        />
        {showInfoFull && (
          <div
            className={style.fullInfo}
            dangerouslySetInnerHTML={{ __html: marked(placeInfoFull || "") }}
          >
            {/* <br />
            {splitedInfoFull[1]} */}
          </div>
        )}
        {/* {!place.secondBreak && (
        <div className={style.time}>
          {t("24hours")} <br />
          {t("technical_break")}
        </div>
      )} */}
        {children}
      </div>
    );
  },
);
