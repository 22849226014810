import { getEnv } from "mobx-state-tree";
import { Environment } from "./Environment";

/**
 * Adds a environment property to the node for accessing our
 * Environment in strongly typed.
 */
export const withEnvironment = () => ({
  views: {
    /**
     * The environment.
     */
    get environment() {
      return getEnv(Environment);
    },
  },
});
