import React from "react";
import ReactModal from "react-modal";
import cn from "classnames";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";

import { Header, Footer, Toast, ApproveNumberForm } from "../../components";
import { PrivacyPolicy, TermOfUse, EducationalLicense, LicenseRegistry } from "../";
import ErrorBoundary from "./ErrorBoundary";
import style from "./modal.module.scss";
import close from "./close.svg";

export const DefaultLayout = ({ children }) => {
  const { t } = useTranslation();

  const login = (props) => {
    return (
      <div className={style.madalInner}>
        <div className={style.madalHead}>
          <div className="title-site title-site_h2">{t("modal_title")}</div>
        </div>
        <ApproveNumberForm {...props} />
      </div>
    );
  };

  const MODALS_COMPONENTS = {
    LOG_IN: login,
    PRIVACY: PrivacyPolicy,
    TERMOFUSE: TermOfUse,
    EDUCATIONAL_LICENSE: LicenseRegistry,
    LICENSE_REGISTRY: EducationalLicense,
  };

  const [modal, changeModal] = React.useState({
    isOpen: false,
    name: "",
  });
  const ModalComponent = MODALS_COMPONENTS[modal.name];
  return (
    <>
      <Header changeModal={changeModal} />
      <ErrorBoundary>
        {React.cloneElement(children, { changeModal })}
      </ErrorBoundary>

      <Footer changeModal={changeModal} />
      <Toast />
      <ReactModal
        isOpen={modal.isOpen}
        ariaHideApp={false}
        className={cn({ [`${style[modal.name]}`]: modal.name })}
        onRequestClose={() => changeModal({ isOpen: false, name: "" })}
      >
        <React.Fragment>
          <ReactSVG
            src={close}
            className={style.close}
            onClick={() => changeModal({ isOpen: false, name: null })}
          />
          {ModalComponent && (
            <ModalComponent changeModal={changeModal} header />
          )}
        </React.Fragment>
      </ReactModal>
    </>
  );
};
