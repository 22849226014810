import { types, resolveIdentifier } from "mobx-state-tree";
import { ProductModel } from "./ProductModel";

export const ProductStore = types
  .model("OrderStore", {
    products: types.optional(types.array(ProductModel), []),
  })
  .actions((self) => ({
    add(item) {
      const resolved = resolveIdentifier(ProductModel, self, item.id)
      if (resolved) {
        Object.assign(resolved, item)
        return;
      };
      self.products.push(ProductModel.create({...item}))
    }
  }))
