import React from "react";
import InputMask from "react-input-mask";

import Field from "../Field/Field";
import style from "./input.module.scss";

export const Input = ({ ...props }) => {
  const Component = (
    <InputMask
      onChange={(e) => props.onChange(e.target.value)}
      className={style.input}
      onBlur={props.onBlur}
      autoComplete={props.autoComplete}
      mask={props.mask}
      disabled={props.disabled}
      type={props.type || "text"}
    />
  );
  return <Field Component={Component} {...props} />;
};
