import React from "react";

import style from "./politics.module.scss";

export const PrivacyPolicyENG = () => (
  <div className={style.wrap}>
    <section className={style.section}>
      <h1>Privacy Policy</h1>
      Before using the «Клиника Архимед» service (the Service), read this
      Privacy Policy (the Privacy Policy) and Terms of Use for the
      Service.&nbsp; The fact of using the Service in any manner (both
      intentionally and unintentionally) is recognized as acceptance, i.e. the
      full and unconditional consent of any user of the Service (the User) to
      use the Service on the conditions set out below.&nbsp; The Service may be
      used only under the terms and conditions of the Terms of Use and the
      Privacy Policy.&nbsp; If the User does not fully accept the terms and
      conditions of these documents, the User is not entitled to use the Service
      for any purpose.
      <br />
      <br />
      <br />
      <h2 className="t-redactor__h2">Terms and definitions</h2>The following
      terms are used in this Privacy Policy: <br />
      <br />
      <ol>
        <li>
          <strong>Personal Data</strong> is any information related to a
          directly or indirectly defined or determined User of the Service (an
          individual, personal data subject).&nbsp;
        </li>
        <li>
          <strong>Processing of Personal Data</strong> is any action (operation)
          or a set of actions (operations) performed, using automation tools or
          without using such tools, with personal data including collection,
          recording, systematization, accumulation, storage, clarification
          (updating, changing), extraction, use, transfer (distribution,
          provision, access), depersonalization, blocking, deletion, destruction
          of personal data.
        </li>
        <li>
          <strong>Confidentiality of Personal Data</strong> is a mandatory
          requirement for the Operator or other person who has gained access to
          the personal data to prevent the personal data distribution without
          the consent of the personal data subject or any other legal grounds.
        </li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">
        <strong>General provisions</strong>
      </h2>
      <ol>
        <li>
          This Privacy Policy is a public offer of ARHIMED CLINIC LLC, INN (TIN)
          5003055720, address: 68 Vavilova St, building 2, Moscow 119261, email:
          info@arhimedlab.ru, tel.: +7 (499) 288 07 26 (the Operator), which
          defines the processing and protection procedures of the information of
          the User of the Service.&nbsp;
        </li>
        <li>
          The purpose of this Privacy Policy is to ensure adequate protection of
          information about the Users, including their personal data, from
          unauthorized access and disclosure.&nbsp;
        </li>
        <li>
          Collection, storage, distribution and protection of information about
          the Users are governed by this Privacy Policy, other official
          documents of the Operator and Russian law.&nbsp;
        </li>
        <li>
          The Privacy Policy comes into force on the User authorization.&nbsp;
        </li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">
        <strong>Terms of use of the Service&nbsp;</strong>
      </h2>
      The Operator, acting reasonably and in good faith, believes that when
      using the Service, the User:&nbsp; <br />
      <br />
      <ol>
        <li>
          Has all the rights necessary to register with and use the
          Service.&nbsp;
        </li>
        <li>
          Indicates reliable information about the User to the extent necessary
          to use the Service.&nbsp;
        </li>
        <li>
          Is familiar with this Privacy Policy, agrees with its provisions and
          assumes the rights and obligations specified in it.&nbsp;
        </li>
      </ol>
      The Operator does not verify the accuracy of the information received
      (collected) about the Users, except for cases when such verification is
      necessary in order to fulfill the Operator's obligations to the User.
      <br />
      <br />
      <br />
      <h2 className="t-redactor__h2">Content of the User data</h2>The Operator
      may collect the following categories of the User personal data while using
      the Services:&nbsp; <br />
      <br />
      <ol>
        <li>
          Information provided by the User during registration (authorization),
          i.e. phone number, last name, first name, patronymic (if any), birth
          date.&nbsp; For the purposes of safe use of the Service and proper
          notification, the User can be sent SMS messages with a password code
          for authorization, a link to an appointment, as well as a link to the
          test result.
        </li>
        <li>The passport series and number to register for a test</li>
        <li>Date and time of access to the Service</li>
        <li>
          Data additionally provided by the Users at the request of the Operator
          in order to fulfill the Operator's obligations to the Users.&nbsp; The
          Operator is entitled, in particular, to request from the User a copy
          of an identity document or other document stating the User's first
          name, last name, photo and other information that, at the Operator's
          discretion, will be necessary and sufficient to identify such a User
          and will allow exclude improper use and violation of the rights of
          third parties. &nbsp;
        </li>
        <li>
          Health state data (the results of medical examinations, tests,
          etc.)&nbsp;
        </li>
        <li>
          Other information required for processing in accordance with the
          provisions governing the use of the Service.
        </li>
      </ol>
      The Operator does not collect data for the purpose of creating the User
      profile to the extent that this may significantly affect the User's rights
      and freedoms in accordance with applicable law. &nbsp; <br />
      <br />
      <br />
      <h2 className="t-redactor__h2">
        Purposes of Processing of Personal Data&nbsp;
      </h2>
      The Operator always processes personal data for specific purposes and only
      the personal data that is relevant to the achievement of such goals.&nbsp;
      In particular, the Operator processes the User personal data for the
      following purposes:
      <br />
      <br />
      <ol>
        <li>
          To fulfill the obligations of the Operator to the Users on the use of
          the Service.
        </li>
        <li>To provide access to the Service.</li>
        <li>
          To communicate with the User and send notifications, requests and
          information on the operation of the Service, fulfill agreements and
          process the User requests.
        </li>
        <li>To improve the usability of the Service.</li>
        <li>To protect the rights of the User and Operator.</li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">
        <strong>
          Terms and conditions of Processing of Personal Data&nbsp;
        </strong>
      </h2>
      The Processing of Personal Data is performed based on the following
      principles:&nbsp; <br />
      <br />
      <ol>
        <li>
          The legality of the purposes and methods of processing the personal
          data.
        </li>
        <li>Good faith.</li>
        <li>
          Compliance of the purposes of Processing of Personal Data with the
          purposes preliminarily determined and announced when collecting the
          personal data, as well as with the powers of the Operator.
        </li>
        <li>
          Compliance of the volume and nature of the processed personal data,
          methods of processing of the personal data to the purposes of
          Processing of Personal Data.
        </li>
        <li>
          Inadmissibility of combining databases, containing personal data,
          created for incompatible purposes.
        </li>
      </ol>
      The Operator processes the User's personal data in order to fulfill the
      paid medical services agreement between the Operator and the User.&nbsp;
      According to Article 6 of the <em>On Personal Data </em>Federal Law No.
      152-ФЗ(FZ) of July 27, 2006 a specific consent of the User for the
      processing of their personal data is not required.&nbsp; According to
      Article 22.2.2 of the above law, the Operator is entitled to process the
      personal data without notifying the authorized body for the protection of
      the rights of subjects of the personal data.&nbsp;
      <br />
      If the User believes that any information that the Operator stores/uses is
      incorrect or incomplete, the User is entitled to log into the User account
      and correct the information. <br />
      If this is provided by applicable law, the User is entitled to:&nbsp;{" "}
      <br />
      <br />
      <ol>
        <li>
          Require the deletion of personal data or part of it and to withdraw
          consent to the Processing of Personal Data
        </li>
        <li>
          Request for restrictions on the Processing of Personal Data&nbsp;
        </li>
        <li>
          Object to the Processing of Personal Data, if provided by applicable
          law.
        </li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">
        <strong>Storage and use of personal data&nbsp;</strong>
      </h2>
      <ol>
        <li>
          The User Personal Data will be processed and stored in the Russian
          Federation in Yandex.Cloud.&nbsp;
        </li>
        <li>
          The User Personal Data is stored strictly on electronic media and
          processed using automated systems, except for cases when manual
          processing of the personal data is necessary subject to law.
        </li>
        <li>
          The Operator takes all necessary measures to protect the User's
          Personal Data from unauthorized access, alteration, disclosure or
          destruction.&nbsp; The Operator provides access to the User's Personal
          Data only to those Users/individuals who need this information for the
          purposes of the Service and to ensure the functioning of the Service
          and to perform other actions not prohibited by Russian law. &nbsp;
        </li>
        <li>
          The Operator is entitled to use the information provided by the User,
          including the Personal Data, in order to ensure compliance with
          Russian law and also to prevent and/or terminate illegal and/or
          unlawful actions of the Users.&nbsp;
        </li>
        <li>
          The information provided by the User may be disclosed only in
          accordance with Russian law at the request of the court, law
          enforcement agencies and in other cases stipulated by Russian law.
          &nbsp;
        </li>
        <li>
          The User is notified and agrees that, for the purposes of quality
          control, the Operator is entitled to analyze the information
          transferred and received by the User in anonymized, aggregated form
          without the possibility of attributing such information to a directly
          or indirectly identified or being identified User. &nbsp;
        </li>
        <li>
          The Operator will store the personal data for as long as necessary to
          achieve the purpose it was collected for or to comply with the laws
          and regulations.
        </li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">
        <strong>Transfer of personal data&nbsp;</strong>
      </h2>
      <ol>
        <li>
          The Personal Data of the Users is not transferred to any third
          parties, except for cases expressly provided for in this Privacy
          Policy and for the purposes of using the Service.&nbsp;
        </li>
        <li>
          The Personal Data of the Users may be transferred to third parties to
          support the function of a payment system if such third parties accept
          obligations to maintain the confidentiality of the User Personal
          Data.&nbsp; The{" "}
          <a href="http://cloudpayments.ru/">cloudpayments.ru </a>service is
          used to make payments.&nbsp;
        </li>
        <li>
          In other cases, if the User is specified or there is the consent of
          the User, the User's Personal Data may be transferred to third
          parties, the counterparties of the Operator, if such counterparties
          accept obligations to maintain the confidentiality of the User
          Personal Data.
        </li>
        <li>
          The Personal Data of the Users is provided at the request of
          government bodies (local authorities) in the manner prescribed by law.
        </li>
      </ol>
      <br />
      <br />
      <h2 className="t-redactor__h2">
        <strong>Destruction of the Personal Data&nbsp;</strong>
      </h2>
      The Personal Data of the User is destroyed when:&nbsp; <br />
      <br />
      <ol>
        <li>The User deletes data from the Account of the User.&nbsp;</li>
        <li>
          The Administration of the Service removes information posted by the
          User, as well as the Account of the User in cases established by
          law.&nbsp;
        </li>
      </ol>
      If the Account is deleted, the Operator stores the personal and other
      necessary data of the User on its electronic media for the period
      necessary and established by Russian law.&nbsp;
      <br />
      <br />
      <br />
      <h2 className="t-redactor__h2">Other terms and conditions</h2>
      <ol>
        <li>
          This Privacy Policy may be amended by the Operator without any special
          notice, and the new version of the Privacy Policy comes into force on
          its posting on the Internet at the address specified in this
          paragraph, unless otherwise provided by the new version of the Privacy
          Policy.&nbsp; The effective version of the Privacy Policy is available
          at: https://life-air.org/en/tpost/1zi6ad6ia2-privacy-policy , unless
          otherwise specified in the Privacy Policy.
        </li>
        <li>
          This Privacy Policy is governed by and construed in accordance with
          the laws of the Russian Federation.&nbsp; For all issues not covered
          by this Privacy Policy, the parties are governed by Russian law.
          &nbsp;
        </li>
        <li>
          If any provision of this Privacy Policy is found invalid or
          unenforceable for any reason, the validity or enforceability of the
          remaining provisions of the Privacy Policy is not in any way affected
          or impaired.&nbsp;
        </li>
        <li>
          If the Operator has made any amendments to this Privacy Policy the
          User does not agree with, the Operator shall stop using the Service.
        </li>
        <li>
          The User grants the Operator the right to send information and
          advertising messages about the scheduled Consultations, the results of
          the Consultations, the availability and price of the Consultations and
          the procedure for using the Service to the User by e-mail or to mobile
          phone number. The User is aware that the User is entitled to refuse to
          receive messages at any time by notifying the Operator at the
          specified email address or to the mobile phone number of the
          User.&nbsp;
        </li>
      </ol>
    </section>
  </div>
);
