import React from "react";
import { useTranslation } from "react-i18next";
import style from "./nomatchpage.module.scss";

export function NoMatchPage() {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className={style["page-404"]}>
        <div className={style["page-404__m"]}>404</div>
        <span className={style["page-404__text"]}>
          {t("no_match_page.not_found")}
        </span>
      </div>
    </div>
  );
}
