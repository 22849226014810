import React from "react";
import { useTranslation } from "react-i18next";

import styleHome from "../../home.module.scss";
import style from "./stepsSection.module.scss";

import step1Img from "../../img/step1.svg";
import step2Img from "../../img/step2.svg";
import step3Img from "../../img/step3.svg";
import step4Img from "../../img/step4.svg";

export function StepsSection() {
  const { t } = useTranslation();

  return (
    <div className={`${styleHome["st-section"]} ${style["steps-section"]}`}>
      <div className="container">
        <div className={styleHome["st-section__head"]}>
          <div className="title-site title-site_h2 text_light">
            {t("landing.steps.title")}
          </div>
        </div>
        <div className="container">
          <ul className={style["steps"]}>
            <li>
              <div className={style["steps__img"]}>
                <img src={step1Img} alt="" />
              </div>
              <div className={style["steps__title"]}>
                {t("landing.steps.step_1_title")}
              </div>
              <div className={style["steps__about"]}>
                {t("landing.steps.step_1_text")}
              </div>
            </li>

            <li>
              <a href="./contract-form.pdf" className={style["no-style-link"]}>
                <div className={style["steps__img"]}>
                  <img src={step2Img} alt="" />
                </div>
                <div className={style["steps__title"]}>
                  {t("landing.steps.step_2_title")}
                </div>
                <div className={style["steps__about"]}>
                  {t("landing.steps.step_2_text")}
                </div>
              </a>
            </li>

            <li>
              <div className={style["steps__img"]}>
                <img src={step3Img} alt="" />
              </div>
              <div className={style["steps__title"]}>
                {t("landing.steps.step_3_title")}
              </div>
              <div className={style["steps__about"]}>
                {t("landing.steps.step_3_text")}
              </div>
            </li>

            <li>
              <div className={style["steps__img"]}>
                <img src={step4Img} alt="" />
              </div>
              <div className={style["steps__title"]}>
                {t("landing.steps.step_4_title")}
              </div>
              <div className={style["steps__about"]}>
                {t("landing.steps.step_4_text")}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
