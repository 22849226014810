import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSpring, animated } from "react-spring";

import { Button } from "../../../../components";
import styleHome from "../../home.module.scss";
import style from "./mainSection.module.scss";
import peopleImg from "../../img/people.svg";

const calc = (x, y) => [
  -(y - window.innerHeight / 2) / 20,
  (x - window.innerWidth / 2) / 20,
  1.1,
];
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export const MainSection = () => {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  const history = useHistory();

  const handleClick = () => {
    history.push("/order");
  };

  const { t } = useTranslation();

  const [isHover, setIsHover] = React.useState(false);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setIsHover(true);
    }
  }, []);

  return (
    <div className={`${styleHome["st-section"]} ${style["main-section"]}`}>
      <div className="container">
        <div className={style["m-row"]}>
          <div
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className={`${style["col-about"]} ${isHover ? style["hover"] : ""}`}
          >
            <h1>{t("landing.main.title")}</h1>
            <div
              onMouseOver={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              className={`${style["m-sub-title"]} ${isHover ? style["hover"] : ""}`}
            >
              {t("landing.main.sub_title")}
            </div>
            {isHover && (
              <p onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                <i>{t("biomaterial_collection_time")}</i>
              </p>
            )}
            <Button onClick={handleClick}>{t("landing.main.button")}</Button>
          </div>

          <div className={style["col-img"]}>
            <animated.div
              className="card"
              onMouseMove={({ clientX: x, clientY: y }) =>
                set({ xys: calc(x, y) })
              }
              onMouseLeave={() => set({ xys: [0, 0, 1] })}
              style={{ transform: props.xys.interpolate(trans) }}
            >
              <img src={peopleImg} alt="people" />
            </animated.div>
          </div>
        </div>
      </div>
    </div>
  );
};
