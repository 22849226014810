import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import { useStores } from "../../models";

import { Button, Card } from "..";

import style from "./form.module.scss";

export const ChooseTestPlace = observer(({ numberRef }) => {
  const { placeStore, toastStore } = useStores();
  const { pending, places, error } = placeStore;
  const { setFieldValue, values } = useFormikContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (!error) return;
    toastStore.addError(error);
  }, [error, toastStore]);

  if (pending) {
    return (
      <div className={style.center}>
        <Loader
          type="Watch"
          color="#afcc54"
          height={100}
          width={100}
          visible={pending}
        />
      </div>
    );
  }

  const placesToShow = places
    .filter((place) => place.show === true)
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const onButtonClick = (id) => {
    setFieldValue("place", id);
    numberRef.current.scrollIntoView();
  };

  return (
    <div className={style.cardsWrapper}>
      {placesToShow.map((item, index) => (
        <Card key={index} choosed={values.place === item.id} place={item} t={t}>
          {values.place === item.id ? (
            <Button uitype={values.place === item.id ? "disabled" : ""}>
              {t("choosed")}
            </Button>
          ) : (
            <Button onClick={() => onButtonClick(item.id)}>
              {t("choose")}
            </Button>
          )}
        </Card>
      ))}
    </div>
  );
});
