import { types, getRoot } from "mobx-state-tree";
import { ProductModel } from "./ProductModel";
import { OrderModel } from "./OrderModel";

export const totalForPatientWithDiscount = (patientsProducts) => {
  const countSamplings = {};
  let sum = 0;
  for (let product of patientsProducts) {
    countSamplings[product.samplingCode] =
      countSamplings[product.samplingCode] + 1 || 1;
    sum = sum + product.price;
    if (countSamplings[product.samplingCode] > 1) {
      sum = sum - product.samplingPrice;
    }
  }
  return sum;
};

export const countDiscountForPatient = (patientsProducts) => {
  const countSamplings = {};
  let discount = 0;
  for (let product of patientsProducts) {
    countSamplings[product.samplingCode] =
      countSamplings[product.samplingCode] + 1 || 1;
    if (countSamplings[product.samplingCode] > 1) {
      discount = discount + 250;
    }
  }
  return discount;
};

export const PatientModel = types
  .model("Patient")
  .props({
    id: types.identifier,
    lastName: types.string,
    firstName: types.string,
    middleName: types.string,
    firstNameLastNameEng: types.string,
    dateOfBirth: types.string,
    passport: types.string,
    address: types.string,
    workOrStudyPlace: types.string,
    flightNumber: types.string,
    sex: types.string,
    order: types.maybeNull(types.reference(types.late(() => OrderModel))),
    product: types.maybeNull(types.reference(types.late(() => ProductModel))),
    products: types.maybeNull(types.array(types.reference(types.late(() => ProductModel)))),
    documentTypeId:  types.maybeNull(types.number),
    series: types.maybeNull(types.string),
    num: types.maybeNull(types.string),
    issueDate: types.maybeNull(types.string),
    issuedBy: types.maybeNull(types.string),
  })
  .actions((self) => ({})) // eslint-disable-line
  .views((self) => ({
    get referral() {
      return getRoot(self).referralStore?.referrals?.find(
        (referral) => referral.patient?.id === self.id,
      ) || null;
    },
    get totalWithDiscount() {
      return totalForPatientWithDiscount(self.products);
    },
    get countDiscount() {
      return countDiscountForPatient(self.product);
    }
  })); // eslint-disable-line
