import { types, getRoot } from "mobx-state-tree";
import { PlaceModel } from "./PlaceModel";

export const OrderModel = types
  .model("Order", {
    id: types.identifier,
    paid: types.maybeNull(types.string, null),
    place: types.maybeNull(types.reference(types.late(() => PlaceModel))),
    createdAt: types.optional(types.string, ""),
    paymentMethod: types.enumeration("PAY_METHODS", ["online", "offline"]),
    sum: types.number,
  })
  .views((self) => {
    return {
      get patients() {
        return getRoot(self).patientStore.patients.filter(
          (patient) => patient.order?.id === self.id,
        );
      },
    };
  });
