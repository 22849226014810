import React from "react";
import { useTranslation } from "react-i18next";
import style from "./success.module.scss";
import { Link } from "react-router-dom";
import { Button } from "../../components";

export const Success = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const InvId = urlParams.get("InvId");
  const OutSum = urlParams.get("OutSum");
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className={style["success-inner"]}>
        <div className={style["success-main"]}>
          <div className={style["success-title"]}>
            {t("success_screen.title")}
          </div>
          <div className={style["success-info"]}>
            <div>
              <span>{t("success_screen.transaction_numbrer")}</span>
              <b>#{InvId}</b>
            </div>
            <div>
              <span>{t("success_screen.transaction_sum")}</span>
              <b>{OutSum}₽</b>
              <br />
              <br />
              <Link to={`/order/${InvId}/`}>
                <Button>{t("get_code")}</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
