import React from "react";
import { useTranslation } from "react-i18next";

import Table from "./Table";
import Panel from "../../components/Panel/Panel";
import style from "./orders.module.scss";
import { useStores } from "../../models";
import { observer } from "mobx-react";
import { Lines } from "react-preloaders";

export const Orders = observer(() => {
  const { t } = useTranslation();
  const { orderStore, referralStore, placeStore } = useStores();
  const { pending, orders } = orderStore;
  const [ loading, setLoading ] = React.useState(true)
  React.useEffect(() => {
    const loadData = async () => {
      await placeStore.getPlaces();
      await referralStore.getRefferals();
      await orderStore.getOrders();
    }
    loadData().then(() => setLoading(false));
  }, [orderStore, placeStore, referralStore]);
  if (loading) {
    return (
      <div>
        <Lines customLoading={pending} />
      </div>
    );
  }
  return (
    <div className={style.main}>
      <div className={style.tableMain}>
        <div className="container">
          <Panel title={t("myOrders")} withoutSpoiler>
            <Table orders={orders} />
          </Panel>
        </div>
      </div>
    </div>
  );
});
