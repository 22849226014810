import { types } from "mobx-state-tree";
import { values } from "mobx";
import { ToastModel } from "./ToastModel";

export const ToastStore = types
  .model("ToastStore", {
    toasts: types.optional(types.array(ToastModel), []),
  })
  .views((self) => ({
    get showMessage() {
      return values(self.toasts)[values(self.toasts).length - 1];
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.toasts = [];
    },
    remove: () => {
      self.toasts.pop();
    },
    addSuccess(success) {
      if (!success) return;
      const successModel = ToastModel.create({
        type: "success",
        message: success,
      });
      self.toasts.push(successModel);
    },
    addError(error) {
      if (!error) return;
      const errorModel = ToastModel.create({ type: "error", message: error });
      self.toasts.push(errorModel);
    },
  }));
