import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import style from "./field.module.scss";

const Field = ({ error, name, i18, cstmSpan, placeholder, ...props }) => {
  const { t } = useTranslation();
  const labelClassName = cn(style.label, {
    [style["label--error"]]: error,
    [props.labelClassName]: props.labelClassName,
  });
  const fieldName = t(i18 || name);

  const Component = React.cloneElement(props.Component, {
    placeholder,
    value: props.value,
  });
  return (
    <label className={labelClassName}>
      <span className={style.placeholder}>{fieldName}</span>
      <div
        className={cn(style.cmp, { [style["cmp--disabled"]]: props.disabled })}
      >
      <>
        {Component}
      </>
      </div>
      {cstmSpan?.()}
      {error && <span className={style.error_msg}>{error}</span>}
    </label>
  );
};

export default Field;
