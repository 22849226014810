import React from "react";
import cn from "classnames";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import { FieldArray, Field, useFormikContext } from "formik";
import { withTranslation } from "react-i18next";

import { Button } from "..";
import { Passport } from "./Passport"

import style from "./form.module.scss";

const FormSpy = ({ value, index, orderTypes, initialValuesPatient }) => {
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    setFieldValue(`patients.${index}.product`, initialValuesPatient.product || '');
  }, [index, initialValuesPatient.product, orderTypes, setFieldValue]);

  React.useEffect(() => {
    if (!value.firstName && !value.lastName) return;
    const engFromCyrillic = cyrillicToTranslit()
      .transform(`${value.firstName} ${value.lastName}`)
      .toUpperCase();
    setFieldValue(`patients.${index}.firstNameLastNameEng`, engFromCyrillic);
  }, [value.firstName, value.lastName, index, setFieldValue]);
  return null;
};

const Form = ({
  patients = [],
  t,
  initialValuesPatient,
  EnhancedField,
  EnhancedSelect,
  EnhancedMultiSelect,
  products,
  i18n,
}) => {
  const [orderTypes, setOrderTypes] = React.useState([]);
  const genderOptions = (t) => {
    return [
      { label: t("gender_man"), value: "man" },
      { label: t("gender_woman"), value: "female" },
    ];
  };
  React.useEffect(() => {
    const productsValues = products?.map((product) => ({
      label: i18n.language === "en" ? product.nameEn : product.name,
      value: product.id,
    }));
    setOrderTypes(productsValues || []);
  }, [i18n.language, products]);
  return (
    <FieldArray
      name="patients"
      render={(arrayHelpers) => {
        return (
          <React.Fragment>
            {patients.map((_item, index) => (
              <div key={index} className={style.patientForm}>
                <FormSpy initialValuesPatient={initialValuesPatient} value={_item} orderTypes={orderTypes} index={index} />
                <div className={style.patientTitle}>
                  {t("patientNumber")} №{index + 1}
                </div>
                <div className={cn(style.row, style["row--start"])}>
                  <div className={style.coll}>
                    <Field
                      component={EnhancedField}
                      autoComplete="last name"
                      name={`patients.${index}.lastName`}
                      i18="lastName"
                    />
                  </div>
                  <div className={style.coll}>
                    <Field
                      autoComplete="first name"
                      component={EnhancedField}
                      name={`patients[${index}]firstName`}
                      i18="firstName"
                    />
                  </div>
                  <div className={style.coll}>
                    <Field
                      autoComplete="patronymic"
                      component={EnhancedField}
                      name={`patients.${index}.middleName`}
                      i18="middleName"
                    />
                  </div>
                </div>
                <div className={cn(style.row, style["row--start"])}>
                  <div className={style.coll}>
                    <Field
                      autoComplete="gender"
                      component={EnhancedSelect}
                      name={`patients.${index}.sex`}
                      placeholder={t("sex")}
                      i18="sex"
                      options={genderOptions(t)}
                    />
                  </div>
                  <div className={style.coll}>
                    <Field
                      component={EnhancedField}
                      name={`patients.${index}.dateOfBirth`}
                      i18="dateOfBirth"
                      mask="99.99.9999"
                    />
                  </div>
                  <div className={style.coll}>
                    <Field
                      component={EnhancedField}
                      name={`patients.${index}.firstNameLastNameEng`}
                      i18="firstNameLastNameEng"
                    />
                  </div>
                </div>
                <div
                  className={cn(
                    style.row,
                    style["row--start"],
                    style["row--last"],
                  )}
                >
                  <div className={style.coll}>
                    <Field
                      component={EnhancedField}
                      name={`patients.${index}.address`}
                      i18="address"
                    />
                  </div>
                  <div className={style.coll}>
                    <Field
                      component={EnhancedField}
                      name={`patients.${index}.workOrStudyPlace`}
                      i18="workOrStudyPlace"
                    />
                  </div>
                  <div className={style.coll}>
                    <Field
                      component={EnhancedField}
                      name={`patients.${index}.flightNumber`}
                      placeholder={t("flightNumber_example")}
                      i18="flightNumber"
                    />
                  </div>
                </div>
                <div className={cn(style.row, style["row--start"])}>
                </div>
                <div className={cn(style.row, style["row--start"])}>
                  <Passport
                    index={index}
                    style={style}
                    EnhancedSelect={EnhancedSelect}
                    EnhancedField={EnhancedField}
                    cn={cn}
                  />
                </div>
                <div className={cn(style.row, style["row--start"])}>
                  <div className={style.coll}>
                    <Field
                      component={EnhancedMultiSelect}
                      name={`patients.${index}.product`}
                      placeholder={t("product")}
                      i18="product"
                      disabled={initialValuesPatient.product.filter(Boolean).length ? true : false}
                      options={initialValuesPatient.product.filter(Boolean).length ?
                        orderTypes?.filter((o) => o.value === initialValuesPatient.product?.[0]) : orderTypes}
                      noOptionsMessage={() => t("noProducts")}
                    />
                  </div>
                </div>
                <div className={style.btnsWrapper}>
                  {index === 0 ? (
                    <Button
                      onClick={() =>
                        arrayHelpers.replace(index, initialValuesPatient)
                      }
                      uitype="gray"
                    >
                      {t("clear")}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => arrayHelpers.remove(index)}
                      uitype="gray"
                    >
                      {t("delete")}
                    </Button>
                  )}
                  {index === patients.length - 1 && (
                    <Button
                      onClick={() => arrayHelpers.push(initialValuesPatient)}
                    >
                      {t("addPatient")}
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </React.Fragment>
        );
      }}
    />
  );
};

export const PatientForm = withTranslation()(Form);
