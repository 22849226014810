import React from "react";
import license from "./icon_license.png";
import license2 from "./icon_license2.png";
import style from "../Politics/politics.module.scss";

export const EducationalLicense = ({ changeModal }) => {
  return (
    <div className={style["wrap"]}>
      <section className={style["section"]}>
        <h2>Выписка из реестра Лицензий и Лицензия на осуществление деятельности</h2>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div style={{ height: 220 }}>
              <img src={license} width={150} alt="Educational license" />
            </div>
            <a
              style={{ fontSize: 14 }}
              href="./registry_1.pdf"
            >
              Скачать выписку
            </a>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
              height: 220,
            }}
          >
            <div style={{ height: 220 }}>
              <img src={license2} width={150} alt="Educational license" />
            </div>
            <a
              style={{ fontSize: 14 }}
              href="./registry_2.pdf"
            >
              Скачать лицензию
            </a>
          </div>
        </div>
        <div style={{ height: 40}}/>
        <p>
          Актуальные виды лицензированных услуг и перечень мест их осуществления
          содержатся в Лицензии №ЛО41-01137-77/00555311 от 27.08.2014,
          размещенной на официальном сайте Федеральной службы по надзору в сфере
          здравоохранения (<a href="https://roszdravnadzor.gov.ru/services/licenses">https://roszdravnadzor.gov.ru/services/licenses</a>),
          выданной Департаментом здравоохранения города Москвы (поиск по номеру
          ИНН 5003055720)
        </p>
      </section>
    </div>
  );
};
