import React, { useEffect, useState } from "react";
import { Routes } from "./routes";
import { RootStoreProvider, setupRootStore } from "./models";
import "./App.css";
import { SocketContext, socket } from './context/socket';


function App() {
  const [rootStore, setRootStore] = useState(null);

  useEffect(() => {
    (async () => {
      setupRootStore().then(setRootStore);
    })();
  }, []);

  if (!rootStore) return null;

  return (
    <RootStoreProvider value={rootStore}>
      <SocketContext.Provider value={socket}>
        <Routes />
      </SocketContext.Provider>
    </RootStoreProvider>
  );
}

export default App;
