import { types } from "mobx-state-tree";
import { PatientModel } from "./PatientModel";

export const PatientStore = types
  .model("PatientStore")
  .props({
    patients: types.optional(types.array(PatientModel), []),
  })
  .actions((self) => ({
    afterCreate() {
      self.clean();
    },
    clean() {
      self.patients = [];
    },
    add(values) {
      const patientIndex = self.patients.findIndex(
        (patient) => values.id === patient.id,
      );
      const patient = PatientModel.create(values);
      if (patientIndex !== -1) {
        self.patients = self.patients.map((p, index) =>
          index === patientIndex ? patient : p,
        );
      } else {
        self.patients.push(patient);
      }
    },
    addOrderPatients(order) {
      order.patients.map((patient) => {
        self.add({
          ...patient,
          order: order.id.toString(),
          product: patient?.product?.toString(),
          products: patient?.products?.map((product) => product.id.toString()),
          id: patient.id.toString(),
          referral: patient.referral ? patient?.referral?.toString() : null,
        });
      }
      );
    },
  }));
