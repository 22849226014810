import * as yup from "yup";
import { documentAttributesShow, documentTypes } from "./Passport.js";

const ENG_LETTERS_REG_EXP = /^[a-zA-Z\s]*$/;

const findDocNameById = (id) => {
  return Object.keys(documentTypes).find(key => documentTypes[key] === +id)
}

const isRequired = (docId, field) => {
  if (!docId || !field) return false;
  const fieldName = findDocNameById(docId)
  if (!fieldName) return false;
  return documentAttributesShow[fieldName][field]
}

const validateDate = (value) => {
  if (typeof value === "undefined") {
    return false;
  }
  const [day, month, year] = value
    .split(".")
    .map((item) => Number.parseInt(item));
  if (day && month && year) {
    const dayValid = day < 32;
    const monthValid = month < 13;
    const yearValid = year > 1850;
    return dayValid && monthValid && yearValid;
  }
  return false;
};

const validProducts = (value) => {
  return value.filter(Boolean).length > 0;
};

export const validattionCnfg = (t) =>
  yup.object({
    place: yup.string(),
    phoneNumberApproved: yup.bool().oneOf([true]),
    paymentMethod: yup.string().oneOf(["offline", "online"]).required(t("form_validation_required")),
    patients: yup
      .array()
      .min(1)
      .of(
        yup.object({
          sex: yup.string().required(t("form_validation_required")),
          // flightNumber: yup.string().required(t("form_validation_required")),
          // product: yup.array.required(t("form_validation_required")),
          product: yup.mixed()
            .test(
              "productValidation",
              t("form_validation_required"),
              (value) => validProducts(value),
            ),
          lastName: yup
            .string()
            .min(1, t("form_validation_too_small"))
            .max(30, t("form_validation_too_big"))
            .required(t("form_validation_required")),
          firstName: yup
            .string()
            .min(1, t("form_validation_too_small"))
            .max(30, t("form_validation_too_big"))
            .required(t("form_validation_required")),
          middleName: yup.string(),
          documentTypeId: yup.string().required(t("form_validation_required")),
          series: yup.string()
            .when('documentTypeId', {
              is: (val) => isRequired(val, 'series'),
              then: yup.string().required(t("form_validation_required")).min(2, t("form_validation_too_small")),
            }),
          num: yup.string()
            // .required(t("form_validation_required"))
            .when('documentTypeId', {
              is: (val) => isRequired(val, 'num'),
              then: yup.string().required(t("form_validation_required")).min(2, t("form_validation_too_small")),
            }),
          issueDate: yup.mixed()
            .when('documentTypeId', {
              is: (val) => isRequired(val, 'issueDate'),
              then: yup
                .mixed()
                .test(
                  "dateValidation",
                  t("form_validation_invalid_date"),
                  (value) => validateDate(value),
                )
                .required(t("form_validation_required")),
            }),
          issuedBy: yup.string()
            .when('documentTypeId', {
              is: (val) => isRequired(val, 'issuedBy'),
              then: yup.string().required(t("form_validation_required")).min(2, t("form_validation_too_small")),
            }),
          // snils: yup.string()
          //   .when('documentTypeId', {
          //     is: (val) => isRequired(val, 'snils'),
          //     then: yup.string().required(t("form_validation_required")).min(6, t("form_validation_too_small")),
          //   }),
          firstNameLastNameEng: yup
            .string()
            .matches(ENG_LETTERS_REG_EXP, t("only_eng_letters"))
            .min(3, t("form_validation_too_small"))
            .max(30, t("form_validation_too_big"))
            .required(t("form_validation_required")),
          dateOfBirth: yup
            .mixed()
            .test(
              "dateValidation",
              t("form_validation_invalid_date"),
              (value) => validateDate(value),
            )
            .required(t("form_validation_required")),
          // passport: yup
          //   .string()
          //   .min(3, t("form_validation_too_small"))
          //   .required(t("form_validation_required")),
          // passportNumber: yup
          //   .string()
          //   .min(3, t("form_validation_too_small"))
          //   .required(t("form_validation_required")),
          // passportAuthority: yup
          //   .string()
          //   .min(3, t("form_validation_too_small"))
          //   .required(t("form_validation_required")),
          address: yup
            .string()
            .min(7, t("form_validation_too_small"))
            // .max(50, t("form_validation_too_big"))
            .required(t("form_validation_required")),
          workOrStudyPlace: yup
            .string()
            .min(2, t("form_validation_too_small"))
            .max(50, t("form_validation_too_big"))
            .required(t("form_validation_required")),
        }),
      ),
  });
