import { onSnapshot } from "mobx-state-tree";
import { RootStoreModel, RootStore } from "./RootStore";
import { Environment } from "./Environment";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as storage from "./storage";
import setupLogRocketReact from "logrocket-react";

/**
 * The key we'll be saving our state as within async storage.
 */
const ROOT_STATE_STORAGE_KEY = "root";

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment() {
  const env = new Environment();
  await env.setup();
  return env;
}

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  let rootStore = RootStore;
  let data;

  LogRocket.init("m8xxdp/labarhime");
  setupLogRocketReact(LogRocket);
  Sentry.init({
    dsn:
      "https://0dd69e2d23cd4058a83fbdd15cb5b6ad@o484821.ingest.sentry.io/5542283",
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  // prepare the environment that will be associated with the RootStore.
  // const env = await createEnvironment();
  try {
    // load data from storage
    data = await storage.load(ROOT_STATE_STORAGE_KEY);
    rootStore = RootStoreModel.create(data || {});
  } catch (e) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    console.log("error root store", e);
    rootStore = RootStoreModel.create({});

    // but please inform us what happened
    // __DEV__ && console.tron.error(e.message, null)
  }

  // reactotron logging
  // if (__DEV__) {
  //   env.reactotron.setRootStore(rootStore, data)
  // }

  // track changes & save to storage
  onSnapshot(rootStore, (snapshot) =>
    storage.save(ROOT_STATE_STORAGE_KEY, snapshot),
  );

  return rootStore;
}
